import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import Counter from "@Atom/Counter";

const AdditionalFacilityCounter = ({
  amount,
  price,
  handleDecrement,
  handleIncrement,
  label,
  ...props
}) => {
  return (
    <div className={Styles.cardWrapper}>
      <Text size="m" width="medium" color="#000">
        {label}
      </Text>
      <div className={Styles.counterWrapper}>
        <div className={Styles.pricing}>
          <Icon icon="user" size="20" className={Styles.icon} />
          <Text size="m" width="medium">
            {price}
            <span className={Styles.text}>/orang</span>
          </Text>
        </div>
        <Counter
          value={amount}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
        />
      </div>
    </div>
  );
};

export default AdditionalFacilityCounter;
