import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Button from "@Atom/Button";
import MUSLIMNET_LOGO from "@Assets/images/logo-muslimnet.png";
import useMediaQuery from "@Hooks/useMediaQuery";
import Table from "@Molecule/Table";
import Modal from "@Atom/Modal";
import SendInvoice from "@Molecule/Modal/SendInvoice";
import { useState } from "react";

const Invoice = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [sendInvoiveModal, setSendInvoiceModal] = useState(false);

  const data = [
    {
      name: "Cameron Williamson",
      roomType: "Quad",
      additional: "Tambah nama passpor",
      cost: "Rp35.000.000",
      subTotal: "Rp35.000.000",
      phoneNumber: "86367282628",
    },
    {
      name: "Brooklyn Simmons",
      roomType: "Triple",
      additional: "-",
      cost: "Rp36.500.000",
      subTotal: "Rp36.500.000",
      phoneNumber: "86367282628",
    },
    {
      name: "Cameron Williamson",
      roomType: "Quad",
      additional: "Tambah nama passpor",
      cost: "Rp35.000.000",
      subTotal: "Rp35.000.000",
      phoneNumber: "86367282628",
    },
    {
      name: "Brooklyn Simmons",
      roomType: "Triple",
      additional: "-",
      cost: "Rp36.500.000",
      subTotal: "Rp36.500.000",
      phoneNumber: "86367282628",
    },
    {
      name: "Cameron Williamson",
      roomType: "Quad",
      additional: "Tambah nama passpor",
      cost: "Rp35.000.000",
      subTotal: "Rp35.000.000",
      phoneNumber: "86367282628",
    },
    {
      name: "Brooklyn Simmons",
      roomType: "Triple",
      additional: "-",
      cost: "Rp36.500.000",
      subTotal: "Rp36.500.000",
      phoneNumber: "86367282628",
    },
    {
      name: "Cameron Williamson",
      roomType: "Quad",
      additional: "Tambah nama passpor",
      cost: "Rp35.000.000",
      subTotal: "Rp35.000.000",
      phoneNumber: "86367282628",
    },
    {
      name: "Brooklyn Simmons",
      roomType: "Triple",
      additional: "-",
      cost: "Rp36.500.000",
      subTotal: "Rp36.500.000",
      phoneNumber: "86367282628",
    },
  ];

  const columns = [
    {
      name: "name",
      label: "Peserta",
      render: (row) => (
        <Text size={isMobile ? "s" : "m"} width="regular" color="#000">
          {row.name}
        </Text>
      ),
    },
    {
      name: "roomType",
      label: "Tipe Kamar",
      render: (row) => (
        <Text size={isMobile ? "s" : "m"} width="regular" color="#000">
          {row.roomType}
        </Text>
      ),
    },
    {
      name: "additional",
      label: "Fasilitas Tambahan",
      render: (row) => (
        <Text size={isMobile ? "s" : "m"} width="regular" color="#000">
          {row.additional}
        </Text>
      ),
    },
    {
      name: "cost",
      label: "Biaya",
      render: (row) => (
        <Text
          size={isMobile ? "s" : "m"}
          width="regular"
          color="#000"
          onClick={() => null}
        >
          {row.cost}
        </Text>
      ),
    },
    {
      name: "subTotal",
      label: "Sub Total",
      render: (row) => (
        <Text
          size={isMobile ? "s" : "m"}
          width="regular"
          color="#000"
          onClick={() => null}
        >
          {row.subTotal}
        </Text>
      ),
    },
  ];

  return (
    <div className={Styles.container}>
      {sendInvoiveModal && (
        <Modal
          handleDone={() => setSendInvoiceModal(false)}
          isOpen={sendInvoiveModal}
        >
          <SendInvoice handleDone={() => setSendInvoiceModal(false)} />
        </Modal>
      )}
      <div className={Styles.buttons}>
        <div className={Styles.button}>
          <Button type="secondary" label="Kembali" />
        </div>
        <div className={Styles.button}>
          <Button
            type="primary"
            label="Kirim Invoice"
            icon="plane"
            onClick={() => setSendInvoiceModal(true)}
          />
        </div>
      </div>
      <div className={Styles.invoiceWrapper}>
        <div className={Styles.header}>
          <div className={Styles.titleWrapper}>
            <Text size={isMobile ? "h4" : "h2"} width="bold" color="#000">
              Invoice
            </Text>
            <Text size={isMobile ? "m" : "xl"} width="medium" color="#54B47F">
              #8302
            </Text>
          </div>
          <img alt="" src={MUSLIMNET_LOGO} />
        </div>
        <div className={Styles.body}>
          <div className={Styles.senderInformation}>
            <div className={Styles.left}>
              <Text size={isMobile ? "s" : "m"} width="400" color="#9e9e9e">
                Dari
              </Text>
              <div className={Styles.sender}>
                <Text size={isMobile ? "l" : "xl"} width="bold" color="#000">
                  Muslimnet
                </Text>
                <Text
                  size={isMobile ? "s" : "m"}
                  width="regular"
                  color="#404040"
                >
                  Jl. W R Supratman No.10B Lantai 2, Kec. Ciputat Timur,
                  Tangerang Selatan, Banten 15412
                </Text>
                <Text
                  size={isMobile ? "s" : "m"}
                  width="regular"
                  color="#404040"
                >
                  WA 081933161811 • admin@muslimnet.com
                </Text>
              </div>
            </div>
            <div className={Styles.right}>
              <Text size={isMobile ? "s" : "m"} width="400" color="#9e9e9e">
                Tanggal
              </Text>
              <Text size={isMobile ? "s" : "m"} width="regular" color="#404040">
                17 Agustus 2023
              </Text>
            </div>
          </div>
          <div className={Styles.summarySection}>
            <Text size={isMobile ? "xl" : "h4"} width="bold" color="#000">
              Ringkasan Pesanan
            </Text>
            <Table data={data} columns={columns} itemsPerPage={10} isMobile />
            <div className={Styles.additionalFeeWrapper}>
              <div className={Styles.additionalFee}>
                <div className={Styles.title}>
                  <Text
                    size={isMobile ? "s" : "m"}
                    width="regular"
                    color="#9e9e9e"
                  >
                    Admin & transfer fee
                  </Text>
                </div>
                <div className={Styles.value}>
                  <Text size={isMobile ? "s" : "m"} width="medium" color="#000">
                    Rp6.500
                  </Text>
                </div>
              </div>
              <div className={Styles.additionalFee}>
                <div className={Styles.title}>
                  <Text
                    size={isMobile ? "s" : "m"}
                    width="regular"
                    color="#9e9e9e"
                  >
                    Pajak
                  </Text>
                </div>
                <div className={Styles.value}>
                  <Text size={isMobile ? "s" : "m"} width="medium" color="#000">
                    Rp100.000
                  </Text>
                </div>
              </div>
              <div className={Styles.additionalFee}>
                <div className={Styles.title}>
                  <Text
                    size={isMobile ? "s" : "m"}
                    width="regular"
                    color="#9e9e9e"
                  >
                    Sub Total
                  </Text>
                </div>
                <div className={Styles.value}>
                  <Text size={isMobile ? "s" : "m"} width="bold" color="#000">
                    Rp183.7000.000
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.footer}>
          <div className={Styles.left}>
            <Text size={isMobile ? "m" : "l"} width="bold" color="#fff">
              Virtual Account
            </Text>
            <div className={Styles.accountInformation}>
              <div className={Styles.informationWrapper}>
                <div className={Styles.title}>
                  <Text
                    size={isMobile ? "s" : "m"}
                    width="regular"
                    color="#ededed"
                  >
                    Bank
                  </Text>
                </div>
                <div className={Styles.information}>
                  <Text
                    size={isMobile ? "s" : "m"}
                    width="regular"
                    color="#ededed"
                  >
                    : BCA
                  </Text>
                </div>
              </div>
              <div className={Styles.informationWrapper}>
                <div className={Styles.title}>
                  <Text
                    size={isMobile ? "s" : "m"}
                    width="regular"
                    color="#ededed"
                  >
                    No virtual account
                  </Text>
                </div>
                <div className={Styles.information}>
                  <Text
                    size={isMobile ? "s" : "m"}
                    width="regular"
                    color="#ededed"
                  >
                    : 877736615536636636
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.right}>
            <div className={Styles.totalCostWrapper}>
              <Text size={isMobile ? "s" : "m"} width="regular" color="#ededed">
                Total pembayaran awal
              </Text>
              <Text size={isMobile ? "h4" : "h2"} width="bold" color="#fff">
                Rp50.000.000
              </Text>
            </div>
            <Text size={isMobile ? "s" : "m"} width="regular" color="#fafafa">
              Harap perhatikan hingga 3 digit terakhir
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
