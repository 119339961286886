import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";

const Searchbar = ({
  value,
  id,
  onChange,
  disabled,
  placeholder,
  type,
  icon,
  onFocus,
  ...props
}) => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <input
          onFocus={onFocus}
          className={Styles.input}
          type={type || "text"}
          id={id || "fname"}
          name="fname"
          value={value || ""}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
        <div className={Styles.iconWrapper}>
          <Icon icon="magnifying-glass" size="20" className={Styles.icon} />
        </div>
      </div>
    </div>
  );
};

export default Searchbar;
