import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import useMediaQuery from "@Hooks/useMediaQuery";

const Card = ({ title, benefits, icon, ...props }) => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={Styles.container}>
      <div className={Styles.titleWrapper}>
        <div className={Styles.iconWrapper}>
          <img
            src={icon}
            alt=""
            width={isMobile ? 24 : 40}
            height={isMobile ? 24 : 40}
          />
        </div>
        <Text size={isMobile ? "xl" : "h3"} width="bold" color="#000">
          {title}
        </Text>
      </div>
      <div className={Styles.benefits}>
        {benefits.length > 0
          ? benefits.map((benefit, id) => (
              <div className={Styles.benefitWrapper} key={id}>
                <div className={Styles.header}>
                  <Icon icon="check-circle" size="24" className={Styles.icon} />
                  <Text
                    size={isMobile ? "l" : "xl"}
                    width="bold"
                    color="#0a0a0a"
                  >
                    {benefit?.title}
                  </Text>
                </div>
                <Text size="m" width="regular" color="#9e9e9e">
                  {benefit?.content}
                </Text>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default Card;
