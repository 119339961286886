import Styles from "./style.module.scss";
import RadioButton from "@Atom/RadioButton";
import Icon from "@Atom/Icon";
import Text from "@Atom/Text";

const FacilityRadioOption = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.left}>
        <Icon icon="facilities" size="24" className={Styles.icon} />
        <div className={Styles.titleWrapper}>
          <Text size="l" width="medium" color="#000">
            Tambah Nama Passpor
          </Text>
          <Text size="m" width="regular" color="#9e9e9e">
            Rp1.500.000 /orang
          </Text>
        </div>
      </div>
      <div>
        <RadioButton />
      </div>
    </div>
  );
};

export default FacilityRadioOption;
