import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";

const Primary = ({ label, disabled, icon, iconOnly, onClick, ...props }) => {
  return (
    <button
      className={!disabled ? Styles.primary : Styles.primaryDisabled}
      onClick={onClick}
    >
      {icon && <Icon icon={icon} size="20" className={Styles.icon} />}
      {!iconOnly && (
        <Text size="m" width="medium" color="#FFFFFF">
          {label}
        </Text>
      )}
    </button>
  );
};

const Secondary = ({ label, disabled, icon, iconOnly, onClick, ...props }) => {
  return (
    <button
      className={!disabled ? Styles.secondary : Styles.secondaryDisabled}
      onClick={onClick}
    >
      {icon && <Icon icon={icon} size="20" className={Styles.icon} />}
      {!iconOnly && (
        <Text size="m" width="medium" color={disabled ? "#C6E6D4" : "#54B47F"}>
          {label}
        </Text>
      )}
    </button>
  );
};

const Red = ({ label, disabled, icon, iconOnly, onClick, ...props }) => {
  return (
    <button
      className={!disabled ? Styles.red : Styles.redDisabled}
      onClick={onClick}
    >
      {icon && <Icon icon={icon} size="20" className={Styles.icon} />}
      {!iconOnly && (
        <Text size="m" width="medium" color={disabled ? "#FEAC9A" : "#D42701"}>
          {label}
        </Text>
      )}
    </button>
  );
};

const Button = ({
  type,
  label,
  disabled,
  icon,
  iconOnly,
  onClick,
  ...props
}) => {
  if (type === "primary") {
    return (
      <Primary
        label={label}
        disabled={disabled}
        icon={icon}
        iconOnly={iconOnly}
        onClick={onClick}
      />
    );
  }
  if (type === "secondary") {
    return (
      <Secondary
        label={label}
        disabled={disabled}
        icon={icon}
        iconOnly={iconOnly}
        onClick={onClick}
      />
    );
  }
  if (type === "red") {
    return (
      <Red
        label={label}
        disabled={disabled}
        icon={icon}
        iconOnly={iconOnly}
        onClick={onClick}
      />
    );
  }
  return (
    <Primary
      label={label}
      disabled={disabled}
      icon={icon}
      iconOnly={iconOnly}
      onClick={onClick}
    />
  );
};

export default Button;
