import { StyleSheet } from "@react-pdf/renderer";

const divider = 1.5;

export default StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "column",
    padding: 24 / divider,
    gap: 24 / divider,
  },
  header: {
    paddingBottom: 24 / divider,
    borderBottomColor: "#ededed",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  headerLeft: {
    display: "flex",
    gap: 8 / divider,
  },
  muslimnetLogo: {
    width: 121 / divider,
    height: 40 / divider,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: 32 / divider,
  },
  invoiceInformation: {
    display: "flex",
    flexDirection: "row",
    gap: 240 / divider,
  },
  informationWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8 / divider,
  },
  addressWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 6 / divider,
    maxWidth: 400 / divider,
  },
  tableWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 16 / divider,
  },
  table: {
    display: "flex",
    flexDirection: "column",
  },
  tableHeader: {
    backgroundColor: "#fafafa",
    paddingVertical: 12 / divider,
    paddingHorizontal: 16 / divider,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
  tableBody: {
    paddingVertical: 12 / divider,
    paddingHorizontal: 16 / divider,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    borderBottomColor: "#ededed",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  costWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 6 / divider,
    alignItems: "flex-end",
    paddingRight: 16 / divider,
  },
  cost: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "40%",
  },
  footer: {
    backgroundColor: "#387855",
    position: "absolute",
    bottom: 0,
    width: "100vw",
    padding: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerLeft: {
    display: "flex",
    flexDirection: "column",
    gap: 6 / divider,
  },
  paymentMethodWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 2 / divider,
  },
  sectionWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 4 / divider,
  },
  section: {
    minWidth: 134,
  },
  footerRight: {
    display: "flex",
    flexDirection: "column",
    gap: 8 / divider,
  },
  footerTotalCostWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 4 / divider,
    alignItems: "flex-end",
  },
});
