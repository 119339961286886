import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Card from "./Card";
import TRAVEL from "../../../assets/images/travel.png";
import TEAM from "../../../assets/images/team.png";
import useMediaQuery from "@Hooks/useMediaQuery";

const Benefits = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const benefits = [
    {
      title: "Memiliki Izin Legalitas Resmi",
      content:
        "Setiap travel yang terdaftar sudah memiliki izin PPIU (Pendirian Penyelenggara Perjalanan Ibadah Umrah (PPIU) dari kementian agama.",
    },
    {
      title: "Tour Leader dan Muthawif Berpengalaman",
      content:
        "Setiap rombongan jamaah akan dibimbing oleh Tour Leader dan Muthawif berkompeten bersertifikat resmi dari BNSP.",
    },
    {
      title: "Kepastian Jadwal dan Rencana Perjalanan",
      content:
        "Setiap travel sudah booking kebutuhan maskapai dan akomodasi selama di tanah suci. Sehingga Jamaah tidak ragu lagi akan kepastian jadwal dan perjalanannya.",
    },
  ];

  const benefits2 = [
    {
      title: "Pendaftaran Yang Mudah dan cepat",
      content:
        "Jamaah akan dibantu pendaftaran oleh CS secara ramah dan mudah tanpa harus melalui birokrasi yang sulit. CS akan mengarahkan program umrah yang tepat sesuai budget, fasilitas dan waktu keberangkatan yang diinginkan. Koorinasi persapan umrah bisa dilakukan by online, tanpa harus berkunjung ke kantor Arima Wisata.",
    },
    {
      title: "Fasilitas yang nyaman",
      content:
        "Travel yang terdaftar akan memberikan fasilitas maksimal kepada setiap jamaahnya sesuai standar Arah Muslim Indonesia. Akomodasi yang nyaman untuk menunjang proses ibadah selama di Tanah Suci..Sistem transportasi yang sudah terintegrasi, makanan fullboard 3x sehari dan penginapan yang nyaman dan dapat dijangkau oleh jamaah merupakan standar Muslim Net yang harus didapatkan oleh jamaah.",
    },
    {
      title: "Pelayanan yang aman",
      content:
        "Adanya kepastian layanan darurat bagi setiap jamaah terhadap kejadian yang tak terduga. Setiap jamaah akan dicover oleh Asuransi Syariah terpercaya.",
    },
    {
      title: "Aplikasi dan Web berbasis User Friendly",
      content:
        "Jamaah cukup mudah mengakses aplikasi atau web muslimnet saat akan mendaftar umrah. Terdapat berbagai info lengkap produk umrah sesuai budget dan fasilitas yang diinginkan.",
    },
  ];
  return (
    <div className={Styles.container}>
      <div className={Styles.titleWrapper}>
        <Text size={isMobile ? "xl" : "h2"} width="regular" color="#fff">
          Mengapa Memilih Kami?
        </Text>
        <Text size={isMobile ? "m" : "l"} width="regular" color="#fafafa">
          Berikut alasan mengapa anda harus memilih kami
        </Text>
      </div>
      <div className={Styles.cards}>
        <Card icon={TRAVEL} title="Travel Agent" benefits={benefits} />
        <Card icon={TEAM} title="Peserta" benefits={benefits2} />
      </div>
    </div>
  );
};

export default Benefits;
