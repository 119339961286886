import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Text from "@Atom/Text";

const DetailsTable = ({ data, ...props }) => {
  return (
    <div className={Styles.container}>
      {data &&
        data.map((card, id) => {
          return (
            <div className={Styles.card} key={id}>
              <div className={Styles.side}>
                <Text size="s" width="regular" color="#9e9e9e">
                  {card.name}
                </Text>
                <div className={Styles.personWrapper}>
                  <Icon icon="user" size="20" className={Styles.icon} />
                  <Text size="m" width="medium" color="#404040">
                    {`${card.amount} orang`}
                  </Text>
                </div>
              </div>
              <div className={Styles.side}>
                <Text size="s" width="regular" color="#9e9e9e">
                  Total biaya
                </Text>
                <Text size="m" width="medium" color="#404040">
                  {`Rp${card.totalCost}`}
                </Text>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default DetailsTable;
