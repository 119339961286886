import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import React, { useState } from "react";
import useMediaQuery from "@Hooks/useMediaQuery";

const Description = ({ title, description, ...props }) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={Styles.descriptionContainer}>
      <Text size={isMobile ? "h4" : "h3"} width="bold" color="#0a0a0a">
        {title}
      </Text>
      <div className={Styles.contentWrapper}>
        <div
          className={isActive ? Styles.textWrapper : Styles.limitedTextWrapper}
        >
          <Text size="m" width="regular" color="#0a0a0a">
            {description}
          </Text>
        </div>
        {!isActive && (
          <div onClick={() => setIsActive(true)} className={Styles.button}>
            Selengkapnya
          </div>
        )}
      </div>
    </div>
  );
};

export default Description;
