import OrderDetail from "@Organism/OrderDetail";
import Navbar from "@Molecule/Navbar";
import Footer from "@Molecule/Footer";

const OrderDetailPage = () => {
  return (
    <>
      <Navbar />
      <OrderDetail />
      <Footer />
    </>
  );
};

export default OrderDetailPage;
