import Styles from "./style.module.scss";
import ILLUSTRATION from "../../../assets/images/footer-illustration.png";
import LOGO from "../../../assets/images/muslimnet-logo.png";
import Text from "@Atom/Text";
import useMediaQuery from "@Hooks/useMediaQuery";
import Icon from "@Atom/Icon";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={Styles.container}>
      <div className={Styles.illustrationWrapper}>
        <img
          src={ILLUSTRATION}
          alt=""
          height={
            useMediaQuery("(min-width:988px)") ? 212 : isMobile ? 80 : 150
          }
        />
      </div>
      <div className={Styles.footerWrapper}>
        <div className={Styles.content}>
          <div className={Styles.leftSection}>
            <img src={LOGO} width={isMobile ? 70 : 160} alt="" />
            <Text size={isMobile ? "l" : "h4"} width="medium" color="#fff">
              Rencanakan Perjalanan Umrah Anda Bersama Kami
            </Text>
          </div>
          <div className={Styles.middleSection}>
            <Text size="xl" width="bold" color="#fff">
              Navigasi
            </Text>
            <div className={Styles.navigationWrapper}>
              <div className={Styles.navigation} onClick={() => navigate("/")}>
                <Icon icon="home-modern" size="16" className={Styles.icon} />
                <span className={Styles.text}>Beranda</span>
              </div>
              <div className={Styles.disabledNavigation}>
                <Icon icon="globe-alt" size="16" className={Styles.icon} />
                <span className={Styles.text}>Paket</span>
              </div>
              <div className={Styles.disabledNavigation}>
                <Icon icon="plane" size="16" className={Styles.icon} />
                <span className={Styles.text}>Segera Berangkat</span>
              </div>
              <div className={Styles.disabledNavigation}>
                <Icon icon="calendar-days" size="16" className={Styles.icon} />
                <span className={Styles.text}>Jadwal Keberangkatan</span>
              </div>
            </div>
          </div>
          <div className={Styles.rightSection}>
            <div className={Styles.info}>
              <div className={Styles.iconWrapper}>
                <Icon icon="envelope" size="16" className={Styles.icon} />
              </div>
              <Text size="m" width="medium" color="#fff">
                info@muslimnet.id
              </Text>
            </div>
            <div className={Styles.info}>
              <div className={Styles.iconWrapper}>
                <Icon icon="phone" size="16" className={Styles.icon} />
              </div>
              <Text size="m" width="medium" color="#fff">
                081933161811
              </Text>
            </div>
            <div className={Styles.info}>
              <div className={Styles.iconWrapper}>
                <Icon icon="map" size="16" className={Styles.icon} />
              </div>
              <Text size="m" width="medium" color="#fff">
                Jl. W R Supratman No.10B Lantai 2, Kec. Ciputat Timur, Tangerang
                Selatan, Banten 15412
              </Text>
            </div>
          </div>
        </div>
        <div className={Styles.footer}>
          <Text size="l" width="regular" color="#FFF">
            Design and develop by Muslim.net (2024)
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Footer;
