import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";

const ErrorCard = ({ errorMessage, ...props }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Icon icon="information-circle" size="24" className={Styles.icon} />
        <Text size="l" width="medium" color="#D42701">
          Error
        </Text>
      </div>
      <Text size="m" width="regular" color="#404040">
        {errorMessage}
      </Text>
    </div>
  );
};

export default ErrorCard;
