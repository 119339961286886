import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import DatePicker from "@Atom/DatePicker";
import Dropdown from "@Atom/DropDown";
import DurationCard from "@Atom/DurationCard";
import { useState } from "react";

const Filter = () => {
  const cities = [
    {
      name: "JAKARTA",
    },
    {
      name: "BANDUNG",
    },
    {
      name: "SURABAYA",
    },
  ];

  const [selectedCity, setSelectedCity] = useState("");

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Text size="h4" width="bold" color="#0a0a0a">
          Filter Paket
        </Text>
        <div className={Styles.resetButton}>Reset</div>
      </div>
      <div className={Styles.inputWrapper}>
        <Text size="l" width="medium" color="#000">
          Asal Keberangkatan
        </Text>
        <Dropdown
          placeholder="Pilih asal keberangkatan"
          iconName="plane"
          iconColor={"#9e9e9e"}
          withIcon
          options={cities}
          setValue={setSelectedCity}
          value={selectedCity}
        />
      </div>
      <div className={Styles.inputWrapper}>
        <Text size="l" width="medium" color="#000">
          Tanggal Keberangkatan
        </Text>
        <DatePicker />
      </div>
      <div className={Styles.inputWrapper}>
        <Text size="l" width="medium" color="#000">
          Durasi Perjalanan
        </Text>
        <div className={Styles.wrapper}>
          <DurationCard text="6 hari" />
          <DurationCard text="8 hari" />
          <DurationCard text="12 hari" />
          <DurationCard text="14 hari" />
        </div>
      </div>
      {/* <Text>Tes</Text> */}
    </div>
  );
};

export default Filter;
