import Styles from "./style.module.scss";

const RadioButton = ({ isActive, setIsActive, disabled, ...props }) => {
  return (
    <div
      onClick={setIsActive}
      className={disabled ? Styles.disabledContainer : Styles.container}
    >
      {isActive && !disabled && <div className={Styles.selectedPoint}></div>}
    </div>
  );
};

export default RadioButton;
