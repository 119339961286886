import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";
import { useState, useEffect } from "react";

const DurationCard = ({
  text,
  selectDuration,
  selectedDuration,
  type,
  ...props
}) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (selectedDuration === text) {
      setIsSelected(true);
    }
    if (selectedDuration !== text) {
      setIsSelected(false);
    }
  }, [selectedDuration, text]);

  return (
    <div
      className={`${Styles.durationCardWrapper} ${
        isSelected ? Styles.selectedDurationCardWrapper : null
      } `}
      onClick={selectDuration}
    >
      <Icon icon="calendar" size="24" className={Styles.icon} />
      <Text size="m" width="medium" color="#000">
        {text}
      </Text>
    </div>
  );
};

export default DurationCard;
