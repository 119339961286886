import Styles from "./inputPhoneNumber.module.scss";

const InputPhoneNumber = ({ placeholder, id, value, onChange, ...props }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.phoneCodeWrapper}>+62</div>
      <input
        className={Styles.input}
        type="number"
        id={id || "fname"}
        name="fname"
        value={value || ""}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputPhoneNumber;
