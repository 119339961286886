import InvoiceDoc from "@Organism/InvoiceDoc";

const InvoiceDocPage = () => {
  return (
    <>
      <InvoiceDoc />
    </>
  );
};

export default InvoiceDocPage;
