import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";

const Information = ({ icon, title, value, ...props }) => {
  return (
    <div className={Styles.container}>
      <Text size="l" width="medium" color="#000">
        {title}
      </Text>
      <div className={Styles.content}>
        {title === "Asal Keberangkatan" ? (
          <Icon icon={icon} size="20" className={Styles.greenIcon} />
        ) : (
          <Icon icon={icon} size="20" className={Styles.icon} />
        )}
        <Text size="m" width="medium" color="#0a0a0a">
          {value}
        </Text>
      </div>
    </div>
  );
};

export default Information;
