import Styles from "./style.module.scss";
import { useOtpInput } from "react-otp-input-hook";

const OTPComponent = ({ onChange, alert, ...props }) => {
  const { register } = useOtpInput({
    onInputValueChange: onChange,
    inputStyle: alert ? Styles.Alert : "",
    type: "numeric",
  });

  const defaultOptions = { required: true };

  return (
    <div className={Styles.container}>
      <input
        {...register("digit-1", defaultOptions)}
        className={alert ? Styles.alert : ""}
      />
      <input
        {...register("digit-2", defaultOptions)}
        className={alert ? Styles.alert : ""}
      />
      <input
        {...register("digit-3", defaultOptions)}
        className={alert ? Styles.alert : ""}
      />
      <input
        {...register("digit-4", defaultOptions)}
        className={alert ? Styles.alert : ""}
      />
      <input
        {...register("digit-5", defaultOptions)}
        className={alert ? Styles.alert : ""}
      />
      <input
        {...register("digit-6", defaultOptions)}
        className={alert ? Styles.alert : ""}
      />
    </div>
  );
};

export default OTPComponent;
