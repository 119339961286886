import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";
import InvoiceRecipientCard from "@Molecule/InvoiceRecipientCard";
import Button from "@Atom/Button";

const SendInvoice = ({ handleDone, ...props }) => {
  const closeModal = () => {
    document.body.style.removeProperty("overflow");
    handleDone();
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Text size="h3" width="bold" color="#54B47F">
          Kirim Invoice
        </Text>
        <div className={Styles.closeButton} onClick={closeModal}>
          <Icon icon="x-mark" size="24" className={Styles.icon} />
        </div>
      </div>
      <div className={Styles.body}>
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
        <InvoiceRecipientCard />
      </div>
      <div className={Styles.footer}>
        <Button label="Kirim Invoice" />
      </div>
    </div>
  );
};

export default SendInvoice;
