import AllPackage from "@Organism/AllPackage";
import Navbar from "@Molecule/Navbar";
import Footer from "@Molecule/Footer";

const AllPackagePage = () => {
  return (
    <>
      <Navbar />
      <AllPackage />
      <Footer />
    </>
  );
};

export default AllPackagePage;
