import { Text } from "@react-pdf/renderer";

const PdfText = ({ children, color, size, type, ...props }) => {
  return (
    <Text
      style={{
        fontSize: size,
        fontFamily: type === "bold" ? "Helvetica-Bold" : "Helvetica",
        color: color,
      }}
    >
      {children}
    </Text>
  );
};

export default PdfText;
