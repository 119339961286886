import Styles from "./style.module.scss";
import RadioButton from "@Atom/RadioButton";
import Text from "@Atom/Text";
import useMediaQuery from "@Hooks/useMediaQuery";

const InvoiceRecipientCard = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={Styles.container}>
      <Text size={isMobile ? "s" : "m"} width="medium" color="#000">
        Cameron Williamson
      </Text>
      <Text size={isMobile ? "s" : "m"} width="regular" color="#0a0a0a">
        +62 81262994188
      </Text>
      <div>
        <RadioButton />
      </div>
    </div>
  );
};

export default InvoiceRecipientCard;
