import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";

const DeveloperInformation = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.iconWrapper}>
        <Icon icon="information-circle" size="20" color="#fff" />
      </div>
      <div className={Styles.textWrapper}>
        <Text size="m" width="medium">
          Saat ini kami masih dalam tahap pengembangan, untuk mengorder ataupun
          informasi lainnya silahkan hubungi di nomor Whatsapp
          {
            <Text size="m" width="semibold">
              {" "}
              081933161811
            </Text>
          }
        </Text>
      </div>
    </div>
  );
};

export default DeveloperInformation;
