import Styles from "./style.module.scss";
import Benefits from "@Molecule/Benefits";
import MainPoster from "@Molecule/MainPoster";
import Recommendations from "@Molecule/Recommendations";
// import ClosestTripSection from "@Molecule/ClosestTripSection";
import FAQSection from "@Molecule/FAQSection";

const Landing = ({ modalOpen, setModalOpen, isMobile, ...props }) => {
  return (
    <>
      <div className={Styles.container}>
        <MainPoster />
        <Recommendations />
        {/* <ClosestTripSection /> */}
        <Benefits />
        <FAQSection />
      </div>
    </>
  );
};

export default Landing;
