import Landing from "@Organism/Landingpage";
import Footer from "@Molecule/Footer";
import Navbar from "@Molecule/Navbar";
import useMediaQuery from "@Hooks/useMediaQuery";

const LandingPage = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <>
      <Navbar />
      <Landing isMobile={isMobile} />
      <Footer />
    </>
  );
};

export default LandingPage;
