import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import DurationModal from "./durationModal";
import MonthOptionsModal from "./monthOptionsModal";
import { useState, useEffect, useRef } from "react";
import useMediaQuery from "@Hooks/useMediaQuery";
import Modal from "@Atom/Modal";
import PackageFilterModal from "@Molecule/Modal/PackageFilter";
import UseOutsideClick from "@Hooks/useOutsideClick";

const PackageFilter = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [modalActive, setModalActive] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [mobileModal, setMobileModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  const openFilter = (type) => {
    if (type === selectedFilter) {
      setModalActive(!modalActive);
    } else {
      setModalActive(true);
    }
    if (type === "departure month") {
      setSelectedFilter("departure month");
    }
    if (type === "duration") {
      setSelectedFilter("duration");
    }
  };

  useEffect(() => {
    if (isMobile) {
      setModalActive(false);
    }
    if (!isMobile) {
      document.body.style.removeProperty("overflow");
      setMobileModal(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (boxOutsideClick) {
      setModalActive(false);
    }
  }, [boxOutsideClick, setModalActive]);

  return (
    <div className={Styles.container}>
      {mobileModal && isMobile && (
        <Modal handleDone={() => setMobileModal(false)} isOpen={true}>
          <PackageFilterModal handleDone={() => setMobileModal(false)} />
        </Modal>
      )}
      <div className={Styles.tabs}>
        <div className={Styles.tab}>
          <Icon icon="plane" className={Styles.icon} size="20" />
          <Text size={isMobile ? "m" : "l"} width="medium" color="#000">
            Umrah
          </Text>
        </div>
      </div>
      <div className={Styles.filterWrapper}>
        <div className={Styles.filterTools}>
          <div className={Styles.filterPickerWrapper}>
            <div
              className={`${Styles.filterPicker} ${
                selectedFilter === "departure month" && modalActive
                  ? Styles.selectedFilterPicker
                  : null
              }`}
              onClick={() =>
                isMobile ? setMobileModal(true) : openFilter("departure month")
              }
            >
              {selectedMonth ? (
                <>
                  <Icon
                    icon="calendar-days"
                    size="20"
                    className={Styles.selectedIcon}
                  />
                  <Text size="m" width="regular" color="#000">
                    {selectedMonth}
                  </Text>
                </>
              ) : (
                <>
                  <Icon
                    icon="calendar-days"
                    size="20"
                    className={Styles.icon}
                  />
                  <Text size="m" width="regular" color="#9e9e9e">
                    Pilih bulan keberangkatan
                  </Text>
                </>
              )}
            </div>
            <div
              className={`${Styles.filterPicker} ${
                selectedFilter === "duration" && modalActive
                  ? Styles.selectedFilterPicker
                  : null
              }`}
              onClick={() =>
                isMobile ? setMobileModal(true) : openFilter("duration")
              }
            >
              {selectedDuration ? (
                <>
                  <Icon
                    icon="calendar-days"
                    size="20"
                    className={Styles.selectedIcon}
                  />
                  <Text size="m" width="regular" color="#000">
                    {selectedDuration}
                  </Text>
                </>
              ) : (
                <>
                  <Icon
                    icon="calendar-days"
                    size="20"
                    className={Styles.icon}
                  />
                  <Text size="m" width="regular" color="#9e9e9e">
                    Pilih durasi perjalanan
                  </Text>
                </>
              )}
            </div>
            <div
              className={
                modalActive ? Styles.modalWrapper : Styles.inactiveModalWrapper
              }
              ref={boxRef}
            >
              {selectedFilter === "duration" ? (
                <DurationModal
                  selectedDuration={selectedDuration}
                  setSelectedDuration={setSelectedDuration}
                  setModalActive={setModalActive}
                />
              ) : (
                <MonthOptionsModal
                  selectedMonth={selectedMonth}
                  setSelectedMonth={setSelectedMonth}
                  setModalActive={setModalActive}
                />
              )}
            </div>
          </div>
          <div className={Styles.buttonWrapper}>
            <Button label="Cari Perjalananmu" icon="magnifying-glass" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageFilter;
