import Styles from "./styles.module.scss";
import Text from "@Atom/Text";
import Button from "@Atom/Button";
import ILLUSTRATION from "../../../assets/images/speaker .png";

const AffiliatorAd = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.leftSide}>
        <img src={ILLUSTRATION} height={62} width={62} alt="" />
        <div className={Styles.textWrapper}>
          <span className={Styles.text}>
            Sudah bergabung bersama&ensp;
            <Text size="xl" width="bold" color="#54B47F">
              Muslimnet Affiliator
            </Text>
            &ensp;?
          </span>
          <Text size="s" width="regular" color="#000">
            Dapatkan komisi dari setiap penjualan paket umroh ini
          </Text>
        </div>
      </div>
      <div className={Styles.rightSide}>
        <Button label="Masuk Sekarang" />
      </div>
    </div>
  );
};

export default AffiliatorAd;
