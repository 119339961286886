import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import Button from "@Atom/Button";
import Input from "@Atom/Input";
import DatePicker from "@Atom/DatePicker";
import Dropdown from "@Atom/DropDown";
import { useState } from "react";
import FacilityRadioOption from "@Molecule/FacilityRadioOption";
import InputPhoneNumber from "@Atom/InputPhoneNumber";

export default function AddParticipant({ handleDone, ...props }) {
  const [gender, setGender] = useState("");
  const [roomType, setRoomType] = useState("");

  const closeModal = () => {
    document.body.style.removeProperty("overflow");
    handleDone();
  };

  const genderOptions = [
    {
      name: "Perempuan",
    },
    {
      name: "Laki-laki",
    },
  ];

  const roomTypeOptions = [
    {
      name: "Quad",
    },
    {
      name: "Triple",
    },
    {
      name: "Double",
    },
  ];

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Text size="h3" width="bold" color="#fff">
          Tambah Peserta
        </Text>
        <div className={Styles.closeButton} onClick={closeModal}>
          <Icon icon="x-mark" size="24" className={Styles.icon} />
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.section}>
          <Text size="xl" width="bold" color="#000">
            Informasi Peserta
          </Text>
          <div className={Styles.form}>
            <div className={Styles.inputWrapper}>
              <div className={Styles.input}>
                <Text size="m" width="medium" color="#000">
                  Nama
                </Text>
                <Input placeholder="Nama peserta" />
              </div>
            </div>
            <div className={Styles.inputWrapper}>
              <div className={Styles.input}>
                <Text size="m" width="medium" color="#000">
                  NIK
                </Text>
                <Input placeholder="NIK peserta" />
              </div>
              <div className={Styles.input}>
                <Text size="m" width="medium" color="#000">
                  Tanggal Lahir
                </Text>
                <DatePicker />
              </div>
            </div>
            <div className={Styles.inputWrapper}>
              <div className={Styles.input}>
                <Text size="m" width="medium" color="#000">
                  Jenis Kelamin
                </Text>
                <Dropdown
                  placeholder="Pilih jenis kelamin"
                  options={genderOptions}
                  setValue={setGender}
                  value={gender}
                />
              </div>
              <div className={Styles.input}>
                <Text size="m" width="medium" color="#000">
                  Email
                </Text>
                <Input placeholder="example@mail.com" />
              </div>
            </div>
            <div className={Styles.inputWrapper}>
              <div className={Styles.input}>
                <Text size="m" width="medium" color="#000">
                  Nomor Whatsapp
                </Text>
                <InputPhoneNumber placeholder="8******" />
              </div>
              <div className={Styles.input}>
                <Text size="m" width="medium" color="#000">
                  Kontak Darurat
                </Text>
                <InputPhoneNumber placeholder="8******" />
              </div>
            </div>
            <div className={Styles.inputWrapper}>
              <div className={Styles.input}>
                <Text size="m" width="medium" color="#000">
                  Nomor Passport
                </Text>
                <Input placeholder="Nomor passport peserta" />
              </div>
              <div className={Styles.input}>
                <Text size="m" width="medium" color="#000">
                  Tipe kamar
                </Text>
                <Dropdown
                  placeholder="Pilih tipe kamar"
                  options={roomTypeOptions}
                  setValue={setRoomType}
                  value={roomType}
                />
              </div>
            </div>
            <div className={Styles.inputWrapper}>
              <div className={Styles.input}>
                <Text size="m" width="medium" color="#000">
                  Alamat Domisili
                </Text>
                <Input placeholder="Alamat peserta" />
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.section}>
          <Text size="xl" width="bold" color="#000">
            Fasilitas Tambahan
          </Text>
          <div className={Styles.additionalWrapper}>
            <FacilityRadioOption />
            <FacilityRadioOption />
          </div>
        </div>
      </div>
      <div className={Styles.footer}>
        <Button type="red" label="Batal" />
        <Button type="primary" label="Simpan" />
      </div>
    </div>
  );
}
