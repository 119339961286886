import LandingPage from "@Views/LandingPage";
import DetailPackagePage from "@Views/DetailPackage";
import LoginPage from "@Views/Login";
import RegisterPage from "@Views/Register";
import AllPackagePage from "@Views/AllPackage";
import OrderDetailPage from "@Views/OrderDetail";
import InvoicePage from "@Views/Invoice";
import InvoiceDocPage from "@Views/InvoiceDoc";

const { createBrowserRouter } = require("react-router-dom");

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "package",
    element: <AllPackagePage />,
  },
  {
    path: "/package/:id",
    element: <DetailPackagePage />,
  },
  {
    path: "/order",
    element: <OrderDetailPage />,
  },
  {
    path: "/invoice",
    element: <InvoicePage />,
  },
  {
    path: "/invoice/:hash",
    element: <InvoiceDocPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <RegisterPage />,
  },
]);

export default router;
