import Styles from "./style.module.scss";
import RadioButton from "@Atom/RadioButton";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";

const TransferCard = ({
  bankName,
  accountNumber,
  accountOwner,
  bankLogo,
  ...props
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.transferWrapper}>
        <div className={Styles.header}>
          <img src={bankLogo} className={Styles.logo} alt="" />
          <Text size="l" width="medium" color="#000">
            {bankName}
          </Text>
        </div>
        <div className={Styles.body}>
          <div className={Styles.content}>
            <div className={Styles.title}>
              <Text size="m" width="regular" color="#9e9e9e">
                No. Rekening
              </Text>
            </div>
            <div className={Styles.withIcon}>
              <Text size="m" width="medium" color="#000">
                {accountNumber}
              </Text>
              <Icon
                icon="document-duplicate"
                size="20"
                className={Styles.icon}
              />
            </div>
          </div>
          <div className={Styles.content}>
            <div className={Styles.title}>
              <Text size="m" width="regular" color="#9e9e9e">
                Pemilik Rekening
              </Text>
            </div>
            <Text size="m" width="medium" color="#000">
              {accountOwner}
            </Text>
          </div>
        </div>
      </div>
      <div>
        <RadioButton />
      </div>
    </div>
  );
};

const VACard = ({ vaNumber, bankName, bankLogo, ...props }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.vaWrapper}>
        <div className={Styles.header}>
          <img src={bankLogo} className={Styles.logo} alt="" />
          <Text size="l" width="medium" color="#000">
            {bankName}
          </Text>
        </div>
        <div className={Styles.body}>
          <Text>Nomor virtual account :</Text>
          <div className={Styles.textIconWrapper}>
            <Text>{vaNumber}</Text>
            <Icon icon="document-duplicate" size="20" className={Styles.icon} />
          </div>
        </div>
      </div>
      <div>
        <RadioButton />
      </div>
    </div>
  );
};

const PaymentMethodCard = ({
  type,
  vaNumber,
  bankName,
  accountNumber,
  accountOwner,
  bankLogo,
  ...props
}) => {
  if (type === "transfer") {
    return (
      <TransferCard
        bankName={bankName}
        accountNumber={accountNumber}
        accountOwner={accountOwner}
        bankLogo={bankLogo}
      />
    );
  }
  if (type === "va") {
    return (
      <VACard vaNumber={vaNumber} bankName={bankName} bankLogo={bankLogo} />
    );
  }
  return (
    <TransferCard
      bankName={bankName}
      accountNumber={accountNumber}
      accountOwner={accountOwner}
      bankLogo={bankLogo}
    />
  );
};

export default PaymentMethodCard;
