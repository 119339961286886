import Styles from "./style.module.scss";

const Poster = ({ image, ...props }) => {
  return (
    <div className={Styles.posterContainer}>
      <img src={image} alt="" style={{ borderRadius: 8 }} />
    </div>
  );
};

export default Poster;
