import Styles from "./style.module.scss";
import Searchbar from "@Atom/Searchbar";
import Text from "@Atom/Text";
import useMediaQuery from "@Hooks/useMediaQuery";

const SearchSection = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={Styles.container}>
      <Text size={isMobile ? "h4" : "h2"} width="regular" color="#fff">
        Daftar Paket Perjalanan Kami
      </Text>
      <div className={Styles.searchbarWrapper}>
        <Searchbar placeholder="Cari paket perjalananmu" />
      </div>
    </div>
  );
};

export default SearchSection;
