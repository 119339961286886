import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import LOGO from "../../../assets/images/muslimnet-logo.png";
import CHECKED from "../../../assets/images/checked.png";
import Input from "@Atom/Input";
import Button from "@Atom/Button";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ErrorCard from "@Atom/ErrorCard";
import OTPComponent from "@Molecule/OTPInput";
import Icon from "@Atom/Icon";

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);
  const [otpTimer, setOtpTimer] = useState(0);

  const secondsToTime = (secs) => {
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return minutes + ":" + seconds;
  };

  useEffect(() => {
    if (step === 2) {
      setOtpTimer(10);
    }
  }, [step]);

  useEffect(() => {
    let otpInterval;
    otpInterval = setInterval(() => {
      setOtpTimer((prev) => (prev > 0 ? prev - 1 : prev));
    }, 1000);
    return () => clearInterval(otpInterval);
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.modal}>
        <div className={Styles.header}>
          <img src={LOGO} height={44} width={"fit-content"} alt="" />
        </div>
        {step === 1 && (
          <div className={Styles.body}>
            {error && (
              <div className={Styles.errorCardWrapper}>
                <ErrorCard errorMessage="Maaf nomor yang Anda masukkan tidak terdaftar" />
              </div>
            )}
            <div className={Styles.textWrapper}>
              <Text size="h3" width="bold" color="#0a0a0a">
                Masuk
              </Text>
              <Text size="m" width="regular" color="#9e9e9e">
                Masuk menggunakan akun yang telah terigistrasi
              </Text>
            </div>
            <div className={Styles.inputSection}>
              <Text size="m" width="medium" color="#000">
                Email/Nomor Whastapp
              </Text>
              <div className={Styles.inputWrapper}>
                <Input
                  type="email"
                  placeholder="Email/nomor whatsapp anda"
                  icon="user-circle"
                  error={error}
                  onFocus={() => setError(false)}
                />
              </div>
            </div>
            <Button label="Masuk" onClick={() => setStep(2)} />
            <div className={Styles.registerNavWrapper}>
              <Text size="m" width="regular" color="#000">
                Belum memiliki akun?
                <span
                  className={Styles.span}
                  onClick={() => navigate("/signup")}
                >
                  {" "}
                  Daftar Sekarang
                </span>
              </Text>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className={Styles.body}>
            <div className={Styles.backWrapper} onClick={() => setStep(1)}>
              <Icon icon="chevron-left" size="24" className={Styles.backIcon} />
              <Text size="m" width="medium" color="#000">
                Kembali
              </Text>
            </div>
            <div className={Styles.content}>
              <div className={Styles.titleWrapperOTP}>
                <Text size="h3" width="bold" color="#0a0a0a">
                  Konfirmasi OTP
                </Text>
                <Text size="m" width="regular" color="#9e9e9e">
                  Kami telah mengirim kode verifikasi ke email
                  <span className={Styles.span}> wiralfin@gmail.com</span>,
                  mohon periksan dan masukan kode di kolom berikut.
                </Text>
              </div>
              <div className={Styles.OTPContainer}>
                <OTPComponent alert={error} />
                {error && (
                  <Text color="#D42701" size="m" width="regular">
                    *Kode OTP yang anda masukan salah
                  </Text>
                )}
                <div className={Styles.timer}>
                  <div
                    className={
                      otpTimer === 0
                        ? Styles.requestOTP
                        : Styles.requestOTPDisabled
                    }
                  >
                    Request again?
                  </div>
                  <Text size="m" width="semibold" color="#0a0a0a">
                    {secondsToTime(otpTimer)}
                  </Text>
                </div>
              </div>
              <div className={Styles.buttonsWrapper}>
                <div className={Styles.buttonWrapper}>
                  <Button label="Konfirmasi" onClick={() => setStep(3)} />
                </div>
              </div>
            </div>
            <div className={Styles.registerNavWrapper}>
              <Text size="m" width="regular" color="#000">
                Belum memiliki akun?
                <span
                  className={Styles.span}
                  onClick={() => navigate("/signup")}
                >
                  {" "}
                  Daftar Sekarang
                </span>
              </Text>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className={Styles.body}>
            <div className={Styles.iconWrapper}>
              <div className={Styles.iconBackground}>
                <img src={CHECKED} height={67} width={67} alt="" />
              </div>
            </div>
            <div className={Styles.content}>
              <div className={Styles.titleWrapper}>
                <Text size="h3" width="bold" color="#0a0a0a">
                  Berhasil
                </Text>
                <Text size="m" width="regular" color="#9e9e9e">
                  Konfirmasi masuk anda telah berhasil berhasil
                </Text>
              </div>
              <div className={Styles.buttonsWrapper}>
                <div className={Styles.buttonWrapper}>
                  <Button label="Ke Halaman Dashboard" />
                </div>
                <div className={Styles.linkText} onClick={() => navigate("/")}>
                  Beranda Website
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
