import Register from "@Organism/Register";
import Navbar from "@Molecule/Navbar";

const RegisterPage = () => {
  return (
    <>
      <Navbar />
      <Register />
    </>
  );
};

export default RegisterPage;
