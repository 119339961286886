import Icon from "@Atom/Icon";
import Styles from "./input.module.scss";

const Input = ({
  value,
  id,
  onChange,
  disabled,
  placeholder,
  type,
  icon,
  error,
  onFocus,
  ...props
}) => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        {icon && (
          <Icon
            icon={icon}
            size="20"
            className={!error ? Styles.icon : Styles.iconError}
          />
        )}
        <input
          onFocus={onFocus}
          className={!error ? Styles.input : Styles.inputError}
          type={type || "text"}
          id={id || "fname"}
          name="fname"
          value={value || ""}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          style={{ paddingLeft: icon ? "40px" : null }}
        />
      </div>
    </div>
  );
};

export default Input;
