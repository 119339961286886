import Navbar from "@Molecule/Navbar";
import Footer from "@Molecule/Footer";
import Invoice from "@Organism/Invoice";

const InvoicePage = () => {
  return (
    <>
      <Navbar />
      <Invoice />
      <Footer />
    </>
  );
};

export default InvoicePage;
