import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Filter from "@Molecule/Filter";
import AffiliatorAd from "@Molecule/AffiliatorAd";
import PackageCard from "@Molecule/PackageCard";
import SearchSection from "./SearchSection";
import Dropdown from "@Atom/DropDown";
import TabButton from "@Atom/TabButton";
import { useState } from "react";

const AllPackage = ({ modalOpen, setModalOpen, isMobile, ...props }) => {
  const [activePage, setActivePage] = useState("all");

  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.searchSectionWrapper}>
          <SearchSection />
        </div>
        <div className={Styles.affiliatorAdWrapper}>
          <AffiliatorAd />
        </div>
        <div className={Styles.newestPackageWrapper}>
          <Text size="h3" width="regular" color="#000">
            Paket Terbaru
          </Text>
          <div className={Styles.cards}>
            <PackageCard />
            <PackageCard />
            <PackageCard />
          </div>
        </div>
        <div className={Styles.allPackageHeadWrapper}>
          <div className={Styles.titleAndTab}>
            <Text size="h3" width="regular" color="#000">
              Semua Paket
            </Text>
            <div className={Styles.tabButtonWrapper}>
              <TabButton
                title="Semua Kategori"
                status={activePage === "all" ? true : false}
                action={() => setActivePage("all")}
              />
              <TabButton
                title="Umrah"
                status={activePage === "umrah" ? true : false}
                action={() => setActivePage("umrah")}
              />
            </div>
          </div>
          <div className={Styles.filterOptionsWrapper}>
            <Text size="m" width="regular" color="#000">
              Menampilkan 1-6 dari 49 produk
            </Text>
            <div className={Styles.dropdownWrapper}>
              <Dropdown
                placeholder="Urutkan berdasarkan"
                iconName="funnel"
                withIcon
                iconColor="#9e9e9e"
              />
            </div>
          </div>
        </div>
        <div className={Styles.allPackageWrapper}>
          <div className={Styles.allCards}>
            <PackageCard type="row" />
            <PackageCard type="row" />
            <PackageCard type="row" />
          </div>
          <div className={Styles.filterWrapper}>
            <Filter />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllPackage;
