import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import POSTER from "../../../assets/images/faq-poster.png";
import FAQCard from "@Molecule/FAQCard";

const FAQSection = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <div className={Styles.imageWrapper}>
          <div className={Styles.mobileTitle}>
            <Text size="xl" width="regular" color="#0a0a0a">
              FAQ
            </Text>
            <Text size="m" width="regular" color="#9e9e9e">
              Frekuensi pertanyaan yang paling banyak kami terima
            </Text>
          </div>
          <img src={POSTER} alt="" />
          <div className={Styles.faqWrapper}>
            <div className={Styles.title}>
              <Text size="h2" width="regular" color="#0a0a0a">
                FAQ
              </Text>
              <Text size="l" width="regular" color="#9e9e9e">
                Frekuensi pertanyaan yang paling banyak kami terima
              </Text>
            </div>
            <div className={Styles.cardWrapper}>
              <FAQCard
                title="Bagaimana cara pesannya?"
                information="Jika pesan online, masukan alamat muslimnet.id, lalu pilih paket keberangkatan sesuai tanggal, fasilitas atau range harga yang diinginkan. Klik paket umrah yg diinginkan, lakukan proses check out. Untuk booking seat minimal pembayaran Rp. 10 Juta. Atau dapat menghubungi ke nomor 081933161811 untuk menanyakan lebih detailnya."
              />
              <FAQCard
                title="Bagaimana saya tau ini bagus untuk saya?"
                information="Semua program umrah muslimnet.id terintegrasi dengan kementrian agama dan pemerintahan arab saudi. Tercatat secara resmi di Sisitem Siskopatuh Kemenag. Terdapat banyak pilihan program umrah yang bisa dipilih sesuai budget, fasilitas dan waktu keberangkatan yang diinginkan. Jamaah dibimbing oleh Tour Leader dan Muthawif Profesional yang sudah memiliki sertfikat resmi dari BNSP."
              />
              <FAQCard
                title="Apakah saya bisa dapat harga terbaik disini?"
                information="Tentunya bisa. Harga yang diberikan menncakup fasilitas dan akomodasi para jamaah selama Perjalanan Umrah sesuai standar muslimnet."
              />
              <FAQCard
                title="Apa benefit yang bisa saya dapatkan?"
                information={`1. Kemudahan saat proses persiapan perjalanan\n2. Penjaminan pelayanan yang memadai\n3. Jadwal perjalanan yang diatur dan disusun dengan baik\n4. Perjalanan yang aman dan nyaman`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
