import Navbar from "@Molecule/Navbar";
import Footer from "@Molecule/Footer";
import DetailPackage from "@Organism/DetailPackage";
import useMediaQuery from "@Hooks/useMediaQuery";
import { useState } from "react";

const DetailPackagePage = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {!modalOpen || !isMobile ? <Navbar /> : null}
      <DetailPackage
        isMobile={isMobile}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      {!modalOpen || !isMobile ? <Footer /> : null}
    </>
  );
};

export default DetailPackagePage;
