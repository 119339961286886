import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import Button from "@Atom/Button";

export default function ParticipantDetail({ handleDone, ...props }) {
  const closeModal = () => {
    document.body.style.removeProperty("overflow");
    handleDone();
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Text size="h3" width="bold" color="#fff">
          Detail Peserta
        </Text>
        <div className={Styles.closeButton} onClick={closeModal}>
          <Icon icon="x-mark" size="24" className={Styles.icon} />
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.profileSection}>
          <div className={Styles.row}>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Nama
              </Text>
              <Text size="m" width="medium" color="#404040">
                Frank Lampard John Terry Petr Cech Ashley Cole Roman Abramovic
              </Text>
            </div>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Tipe paket
              </Text>
              <Text size="m" width="medium" color="#404040">
                Quad
              </Text>
            </div>
          </div>
          <div className={Styles.row}>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                NIK
              </Text>
              <Text size="m" width="medium" color="#404040">
                1115011105970001
              </Text>
            </div>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Tanggal lahir
              </Text>
              <Text size="m" width="medium" color="#404040">
                17 Mei 1997
              </Text>
            </div>
          </div>
          <div className={Styles.row}>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Jenis kelamin
              </Text>
              <Text size="m" width="medium" color="#404040">
                Laki-laki
              </Text>
            </div>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Email
              </Text>
              <Text size="m" width="medium" color="#404040">
                wiraalfin@gmail.com
              </Text>
            </div>
          </div>
          <div className={Styles.row}>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Nomor Whatsapp
              </Text>
              <Text size="m" width="medium" color="#9e9e9e">
                +62 <span className={Styles.span}> 81262994188</span>
              </Text>
            </div>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Kontak darurat
              </Text>
              <Text size="m" width="medium" color="#9e9e9e">
                +62 <span className={Styles.span}> 81262994188</span>
              </Text>
            </div>
          </div>
          <div className={Styles.row}>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Nomor passpor
              </Text>
              <Text size="m" width="medium" color="#404040">
                123456
              </Text>
            </div>
          </div>
          <div className={Styles.row}>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Alamat Domisili
              </Text>
              <Text size="m" width="medium" color="#404040">
                Jalan Kpt. Tendean, Gg. Jati, RT 01/RW 01, Mampang Prapatan,
                Jakarta Selatan, Jakarta
              </Text>
            </div>
          </div>
          <div className={Styles.row}>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Nomor passpor
              </Text>
              <Text size="m" width="medium" color="#404040">
                123456
              </Text>
            </div>
          </div>
          <div className={Styles.row}>
            <div className={Styles.informationWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Alamat Domisili
              </Text>
              <Text size="m" width="medium" color="#404040">
                Jalan Kpt. Tendean, Gg. Jati, RT 01/RW 01, Mampang Prapatan,
                Jakarta Selatan, Jakarta
              </Text>
            </div>
          </div>
        </div>
        <div className={Styles.tableSection}>
          <div className={Styles.tableHeader}>
            <div className={Styles.title}>
              <Text size="l" width="bold" color="#212121">
                Fasilitas
              </Text>
            </div>
            <div className={Styles.title}>
              <Text size="l" width="bold" color="#212121">
                Biaya
              </Text>
            </div>
          </div>
          <div className={Styles.tableRowWrapper}>
            <div className={Styles.tableRow}>
              <div className={Styles.text}>
                <Text size="m" width="regular" color="#000">
                  Tipe kamar : Quad
                </Text>
              </div>
              <div className={Styles.text}>
                <Text size="m" width="regular" color="#212121">
                  Rp35.000.000
                </Text>
              </div>
            </div>
            <div className={Styles.tableRow}>
              <div className={Styles.text}>
                <Text size="m" width="regular" color="#000">
                  Tambah Nama Passpor
                </Text>
              </div>
              <div className={Styles.text}>
                <Text size="m" width="regular" color="#212121">
                  Rp1.000.000
                </Text>
              </div>
            </div>
          </div>

          <>
            <div className={Styles.summaryRow}>
              <div className={Styles.text}>
                <Text size="s" width="regular" color="#9e9e9e">
                  Total Biaya
                </Text>
              </div>
              <div className={Styles.text}>
                <Text size="l" width="bold" color="#212121">
                  Rp36.000.000
                </Text>
              </div>
            </div>
          </>
        </div>
      </div>
      <div className={Styles.footer}>
        <Button type="red" label="Hapus Peserta" icon="trash" />
        <Button type="primary" label="Ubah Data" icon="pencil" />
      </div>
    </div>
  );
}
