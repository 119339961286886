import "./App.scss";
import { RouterProvider } from "react-router-dom";
import router from "@Navigators/index";
import Styles from "./style.module.scss";

function App() {
  return (
    <div className={Styles.container}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
