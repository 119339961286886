import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Counter from "@Atom/Counter";

const PackageCounter = ({
  amount,
  handleDecrement,
  handleIncrement,
  name,
  price,
  ...props
}) => {
  return (
    <div className={Styles.package}>
      <div className={Styles.leftSide}>
        <Text size="s" width="regular" color="#000">
          {name}
        </Text>
        <Text size="m" width="medium" color="#000">
          {price}
        </Text>
      </div>
      <div className={Styles.rightSide}>
        <Counter
          value={amount}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
        />
      </div>
    </div>
  );
};

export default PackageCounter;
