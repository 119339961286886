import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import DurationCard from "@Atom/DurationCard";
import { useEffect, useRef } from "react";
import UseOutsideClick from "@Hooks/useOutsideClick";

const DurationModal = ({
  setSelectedDuration,
  selectedDuration,
  setModalActive,
  ...props
}) => {
  const selectDuration = (text) => {
    setSelectedDuration(text);
    setModalActive(false);
  };

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      document.body.style.removeProperty("overflow");
      setModalActive(false);
    }
  }, [boxOutsideClick, setModalActive]);

  return (
    <div className={Styles.durationModal} ref={boxRef}>
      <Text size="l" width="medium" color="#000">
        Durasi Perjalanan yang Anda Inginkan?
      </Text>
      <div className={Styles.cards}>
        <DurationCard
          text="6 hari"
          selectDuration={() => selectDuration("6 hari")}
          selectedDuration={selectedDuration}
        />
        <DurationCard
          text="8 hari"
          selectDuration={() => selectDuration("8 hari")}
          selectedDuration={selectedDuration}
        />
        <DurationCard
          text="12 hari"
          selectDuration={() => selectDuration("12 hari")}
          selectedDuration={selectedDuration}
        />
        <DurationCard
          text="14 hari"
          selectDuration={() => selectDuration("14 hari")}
          selectedDuration={selectedDuration}
        />
      </div>
    </div>
  );
};

export default DurationModal;
