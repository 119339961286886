/* eslint-disable no-unused-vars */
import Styles from "./style.module.scss";
import React, { useState } from "react";
// import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import PLACEHOLDER_TABLE from "@Assets/images/placeholder-table.png";

const Table = (props) => {
  const {
    data,
    columns,
    isLoading,
    type,
    itemsPerPage,
    placeholderText,
    searchTerm,
    selectedRow,
    isMobile,
  } = props;

  // const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  // const handleChecked = (item) => {
  //   let isSelected = false;
  //   for (let i = 0; i < selectedItems.length; i++) {
  //     if (selectedItems[i]?._id === item?._id) {
  //       isSelected = true;
  //       break;
  //     }
  //   }
  //   return isSelected;
  // };

  // const handleCheckboxChange = (item) => {
  //   let isSelected = handleChecked(item);
  //   if (isSelected) {
  //     setSelectedItems(
  //       selectedItems.filter((selected) => selected?._id !== item?._id)
  //     );
  //   } else {
  //     setSelectedItems([...selectedItems, item]);
  //   }
  // };

  const handleSort = (columnKey) => {
    if (sortColumn === columnKey) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnKey);
      setSortDirection("asc");
    }
  };

  const handleMouseEnter = (rowId) => {
    setHoveredRow(rowId);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  // const handleSelectAll = () => {
  //   if (selectAll) {
  //     setSelectedItems([]);
  //   } else {
  //     setSelectedItems(data);
  //   }

  //   setSelectAll(!selectAll);
  // };

  const sortedData = sortColumn
    ? [...data].sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      })
    : data;

  const filteredData = searchTerm
    ? sortedData.filter((row) => {
        return Object.values(row).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    : sortedData;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredData?.slice(startIndex, endIndex);

  // master
  // const handleCheckedMaster = (item) => {
  //   let isSelected = false;
  //   for (let i = 0; i < selectedUsers.length; i++) {
  //     if (selectedUsers[i]?.nrk === item?.nrk) {
  //       isSelected = true;
  //       break;
  //     }
  //   }
  //   return isSelected;
  // };

  // const handleCheckboxChangeMaster = (item) => {
  //   let isSelected = handleCheckedMaster(item);
  //   if (isSelected) {
  //     setSelectedUsers(
  //       selectedUsers.filter((selected) => selected?.nrk !== item?.nrk)
  //     );
  //   } else {
  //     setSelectedUsers([...selectedUsers, item]);
  //   }
  // };

  return (
    <div className={Styles.body}>
      {isLoading ? (
        <div className={Styles.loading}>
          <Text>Loading...</Text>
        </div>
      ) : (
        <div
          className={
            isMobile ? Styles.tableContainerMobile : Styles.tableContainer
          }
        >
          {data?.length === 0 ? (
            <div className={Styles.placeholder}>
              <img src={PLACEHOLDER_TABLE} alt="placeholder" />
              <span>{placeholderText}</span>
            </div>
          ) : (
            <table
              style={{
                width: type?.toLowerCase()?.includes("sales") ? "100%" : "",
              }}
            >
              <thead>
                <tr>
                  {columns?.map((column) => (
                    <th
                      key={column.name}
                      onClick={() => handleSort(column.name)}
                    >
                      <div className={Styles.column}>
                        {column.label}
                        {/* {sortColumn === column.name && (
                          <span>
                            {sortDirection.direction === "asc" ? (
                              <Icon icon="chevron-up" size="14" />
                            ) : (
                              <Icon icon="chevron-down" size="14" />
                            )}
                          </span>
                        )} */}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        row.id === hoveredRow || row?.id === selectedRow
                          ? Styles.hovered
                          : ""
                      } ${Styles.hoveredFirstRow}`}
                      onMouseEnter={() => handleMouseEnter(row.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {columns?.map((column) => (
                        <td key={column.name}>
                          {column.render
                            ? column.render(row)
                            : row[column.name]}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default Table;
