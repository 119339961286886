import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";

const AffiliatorFeeEstimationCard = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.title}>
          <Icon icon="money" size="20" className={Styles.icon} />
          <Text size="l" width="medium" color="#000">
            Estimasi Pendapatan
          </Text>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.section}>
          <Text size="s" width="regular" color="#fff">
            Total Peserta
          </Text>
          <div className={Styles.textWithIcon}>
            <Icon icon="user-group" size="20" className={Styles.icon} />
            <Text size="m" width="medium" color="#fff">
              8 orang
            </Text>
          </div>
        </div>
        <div className={Styles.section}>
          <Text size="s" width="regular" color="#fff">
            Etimasi pendapatan
          </Text>
          <Text size="m" width="medium" color="#fff">
            Rp1.600.000
          </Text>
        </div>
      </div>
    </div>
  );
};

export default AffiliatorFeeEstimationCard;
