import React, { useState } from "react";
import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";

const FAQCard = ({ title, information, ...props }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className={`${Styles.container} ${
        isActive ? Styles.activeContainer : null
      }`}
      onClick={() => setIsActive(!isActive)}
    >
      <div className={Styles.headSection}>
        <div className={Styles.titleWrapper}>
          <Icon icon="question-mark-circle" size="24" className={Styles.icon} />
          <Text size="l" width="bold" color="#0a0a0a">
            {title}
          </Text>
        </div>
        <div className={Styles.iconWrapper}>
          <Icon
            className={Styles.icon}
            icon={isActive ? "chevron-up" : "chevron-down"}
            size="20"
          />
        </div>
      </div>
      <div
        className={`${Styles.bodySection} ${
          isActive ? Styles.activeBodySection : null
        }`}
      >
        <Text size="m" width="regular" color="#9e9e9e">
          {information}
        </Text>
      </div>
    </div>
  );
};
export default FAQCard;
