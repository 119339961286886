import React from "react";
import { Page, View, Document, PDFViewer, Image } from "@react-pdf/renderer";
import Styles from "./styles";
import MUSLIMNET_LOGO from "@Assets/images/logo-muslimnet.png";
import PdfText from "@Atom/PdfText";
import Footer from "./footer";

const TableHead = () => {
  const divider = 1.5;

  return (
    <View style={Styles.tableHeader}>
      <View style={{ flex: 3 }}>
        <PdfText size={16 / divider} color="#212121" type="bold">
          Peserta
        </PdfText>
      </View>
      <View style={{ flex: 1.5 }}>
        <PdfText size={16 / divider} color="#212121" type="bold">
          Tipe Kamar
        </PdfText>
      </View>
      <View style={{ flex: 2.5 }}>
        <PdfText size={16 / divider} color="#212121" type="bold">
          Fasilitas Tambahan
        </PdfText>
      </View>
      <View style={{ flex: 2, textAlign: "right" }}>
        <PdfText size={16 / divider} color="#212121" type="bold">
          Biaya
        </PdfText>
      </View>
      <View style={{ flex: 2, textAlign: "right" }}>
        <PdfText size={16 / divider} color="#212121" type="bold">
          Sub Total
        </PdfText>
      </View>
    </View>
  );
};

const TableBody = ({ data, ...props }) => {
  const { name, roomType, additional, cost, totalCost } = data;
  const divider = 1.5;

  return (
    <View style={Styles.tableBody}>
      <View style={{ flex: 3 }}>
        <PdfText size={14 / divider} color="#000">
          {name}
        </PdfText>
      </View>
      <View style={{ flex: 1.5 }}>
        <PdfText size={14 / divider} color="#000">
          {roomType}
        </PdfText>
      </View>
      <View style={{ flex: 2.5 }}>
        <PdfText size={14 / divider} color="#000">
          {additional}
        </PdfText>
      </View>
      <View style={{ flex: 2, textAlign: "right" }}>
        <PdfText size={14 / divider} color="#000">
          {cost}
        </PdfText>
      </View>
      <View style={{ flex: 2, textAlign: "right" }}>
        <PdfText size={14 / divider} color="#000">
          {totalCost}
        </PdfText>
      </View>
    </View>
  );
};

const InvoiceDoc = () => {
  const divider = 1.5;
  const data = [
    {
      name: "Cameron Williamson",
      roomType: "Quad",
      additional: "Tambah nama passpor",
      cost: "Rp35.000.000",
      totalCost: "Rp35.000.000",
    },
    {
      name: "Brooklyn Simmons",
      roomType: "Triple",
      additional: "-",
      cost: "Rp36.500.000",
      totalCost: "Rp36.500.000",
    },
    {
      name: "Kristin Watson",
      roomType: "Double",
      additional: "Perlengkapan & heandling",
      cost: "Rp39.200.000",
      totalCost: "Rp39.200.000",
    },
  ];

  return (
    <PDFViewer
      style={{
        width: window.innerWidth,
        height: "100vh",
      }}
    >
      <Document>
        <Page size="A4" style={Styles.page}>
          <View style={Styles.header}>
            <View style={Styles.headerLeft}>
              <PdfText color="#000" size={32 / divider} type="bold">
                Invoice
              </PdfText>
              <PdfText color="#54B47F" size={18 / divider}>
                #8302
              </PdfText>
            </View>
            <Image source={MUSLIMNET_LOGO} style={Styles.muslimnetLogo} />
          </View>
          <View style={Styles.body}>
            <View style={Styles.invoiceInformation}>
              <View style={Styles.informationWrapper}>
                <PdfText size={14 / divider} color="#9e9e9e">
                  Dari
                </PdfText>
                <View style={Styles.addressWrapper}>
                  <PdfText size={18 / divider} type="bold" color="#000">
                    Muslimnet
                  </PdfText>
                  <PdfText size={14 / divider} color="#404040">
                    Jl. W R Supratman No.10B Lantai 2, Kec. Ciputat Timur,
                    Tangerang Selatan, Banten 15412
                  </PdfText>
                  <PdfText size={14 / divider} color="#404040">
                    WA 081933161811 • admin@muslimnet.com
                  </PdfText>
                </View>
              </View>
              <View style={Styles.informationWrapper}>
                <PdfText size={14 / divider} color="#9e9e9e">
                  Tanggal
                </PdfText>
                <PdfText size={14 / divider} color="#404040">
                  17 Agustus 2023
                </PdfText>
              </View>
            </View>
            <View style={Styles.tableWrapper}>
              <PdfText size={20 / divider} color="#000" type="bold">
                Ringkasan Pesanan
              </PdfText>
              <View style={Styles.table}>
                <TableHead />
                {data.map((person, id) => (
                  <TableBody data={person} key={id} />
                ))}
              </View>
              <View style={Styles.costWrapper}>
                <View style={Styles.cost}>
                  <PdfText size={14 / divider} color="#9e9e9e">
                    Admin & transfer fee
                  </PdfText>
                  <PdfText size={14 / divider} color="#000">
                    Rp6.500
                  </PdfText>
                </View>
                <View style={Styles.cost}>
                  <PdfText size={14 / divider} color="#9e9e9e">
                    Pajak
                  </PdfText>
                  <PdfText size={14 / divider} color="#000">
                    Rp100.000
                  </PdfText>
                </View>
                <View style={Styles.cost}>
                  <PdfText size={14 / divider} color="#9e9e9e">
                    Sub total
                  </PdfText>
                  <PdfText size={14 / divider} color="#000">
                    Rp183.7000.000
                  </PdfText>
                </View>
              </View>
            </View>
          </View>
          <Footer type="transfer" />
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default InvoiceDoc;
