import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import React, { useRef } from "react";

const DatePicker = ({ value, setValue, disabled, ...props }) => {
  const dateRef = useRef();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  return (
    <div className={Styles.container}>
      <div
        className={
          !disabled ? Styles.inputWrapper : Styles.disabledInputWrapper
        }
        onClick={!disabled ? () => dateRef.current.showPicker() : () => null}
      >
        <input
          className={Styles.input}
          value={!disabled && value ? formatDate(value) : null}
          onChange={(e) => setValue(new Date(e.target.value))}
          placeholder="Pilih tanggal keberangkatan"
          // type="date"
          ref={dateRef}
          disabled
        />
        <div className={Styles.iconWrapper}>
          <Icon
            className={Styles.icon}
            icon="calendar-days"
            size={24}
            onClick={
              !disabled ? () => dateRef.current.showPicker() : () => null
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
