import { classPicker } from "./function";

const Text = ({ size, color, width, children, ...props }) => {
  return (
    <span className={classPicker(size, width)} style={{ color: color }}>
      {children}
    </span>
  );
};

export default Text;
