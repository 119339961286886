import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";

const Counter = ({ value, handleIncrement, handleDecrement, ...props }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.iconWrapper} onClick={() => null}>
        <Icon
          icon="minus-box"
          className={value === 0 ? Styles.iconDisabled : Styles.icon}
          size="32"
        />
      </div>
      <div className={Styles.valueWrapper}>
        <Text size="l" width="regular" color="#000">
          {value}
        </Text>
      </div>
      <div className={Styles.iconWrapper} onClick={() => null}>
        <Icon icon="plus-box" className={Styles.iconDisabled} size="32" />
      </div>
    </div>
  );
};

export default Counter;
