import Styles from "./style.module.scss";
import PackageFilter from "@Molecule/PackageFilter";
import Text from "@Atom/Text";
import useMediaQuery from "@Hooks/useMediaQuery";

const MainPoster = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={Styles.container}>
      <div className={Styles.poster}>
        <div className={Styles.textWrapper}>
          <Text size={isMobile ? "h4" : "h1"} width="regular" color="#fff">
            Rencanakan Perjalanan Umroh Anda Bersama Kami
          </Text>
          <Text size={isMobile ? "s" : "xl"} width="regular" color="#fff">
            Temukan paket umroh yang sesuai dengan kebutuhan anda
          </Text>
        </div>
      </div>
      {/* <div className={Styles.filterWrapper}>
        <PackageFilter />
      </div> */}
    </div>
  );
};

export default MainPoster;
