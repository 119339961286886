import Styles from "./style.module.scss";
// import Button from "@Atom/Button";
import LOGO from "../../../assets/images/logo-muslimnet.png";
import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import useMediaQuery from "@Hooks/useMediaQuery";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const [burgerOpen, setBurgerOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.leftSide}>
            <img src={LOGO} alt="" width={90} />
            <div className={Styles.menus}>
              <div className={Styles.menuWrapper} onClick={() => navigate("/")}>
                <Icon icon="home-modern" size="16" className={Styles.icon} />
                <div className={Styles.menuTitle}>Beranda</div>
              </div>
              <div className={Styles.disabledMenuWrapper}>
                <Icon icon="globe-alt" size="16" className={Styles.icon} />
                <div className={Styles.menuTitle}>Paket</div>
              </div>
              <div className={Styles.disabledMenuWrapper}>
                <Icon icon="plane" size="16" className={Styles.icon} />
                <div className={Styles.menuTitle}>Segera Berangkat</div>
              </div>
              <div className={Styles.disabledMenuWrapper}>
                <Icon icon="calendar-days" size="16" className={Styles.icon} />
                <div className={Styles.menuTitle}>Jadwal Keberangkatan</div>
              </div>
            </div>
          </div>
          {/* <div className={Styles.buttons}>
            <Button
              type="secondary"
              label="Daftar"
              onClick={() => navigate("/signup")}
            />
            <Button
              type="primary"
              label="Masuk"
              onClick={() => navigate("/login")}
            />
          </div> */}
          {isMobile && (
            <div
              className={Styles.burgerIconWrapper}
              onClick={() => setBurgerOpen(!burgerOpen)}
            >
              <Icon
                icon={burgerOpen ? "x-mark" : "bars-3-bottom-left"}
                size="24"
                className={Styles.icon}
              />
            </div>
          )}
        </div>
        <div className={Styles.menuModal}>
          {burgerOpen && isMobile && (
            <>
              {/* <div className={Styles.buttons}>
                <Button
                  label="Daftar"
                  type="secondary"
                  onClick={() => navigate("/signup")}
                />
                <Button label="Masuk" onClick={() => navigate("/login")} />
              </div> */}
              <div
                className={Styles.navigation}
                onClick={() => {
                  navigate("/");
                }}
              >
                <div className={Styles.button}>
                  <Icon icon="home-modern" size="16" className={Styles.icon} />
                  <Text size="m" width="regular" color="#000">
                    Beranda
                  </Text>
                </div>
              </div>
              <div className={Styles.navigation} onClick={() => null}>
                <div className={Styles.button}>
                  <Icon
                    icon="globe-alt"
                    size="16"
                    className={Styles.disabledIcon}
                  />
                  <Text size="m" width="regular" color="#9e9e9e">
                    Paket
                  </Text>
                </div>
              </div>
              <div className={Styles.navigation} onClick={() => null}>
                <div className={Styles.button}>
                  <Icon
                    icon="plane"
                    size="16"
                    className={Styles.disabledIcon}
                  />
                  <Text size="m" width="regular" color="#9e9e9e">
                    Segera Berangkat
                  </Text>
                </div>
              </div>
              <div className={Styles.navigation} onClick={() => null}>
                <div className={Styles.button}>
                  <Icon
                    icon="calendar-days"
                    size="16"
                    className={Styles.disabledIcon}
                  />
                  <Text size="m" width="regular" color="#9e9e9e">
                    Jadwal Keberangkatan
                  </Text>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={
          burgerOpen && isMobile
            ? Styles.menuModalWrapper
            : Styles.inactiveMenuModalWrapper
        }
        onClick={() => setBurgerOpen(false)}
      ></div>
    </>
  );
};

export default Navbar;
