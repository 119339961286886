import Styles from "./style.module.scss";
import Text from "@Atom/Text";

const ActiveTabButton = ({ title, action, ...props }) => {
  return (
    <div className={Styles.activeContainer} onClick={action}>
      <Text size="m" width="medium" color="#54B47F">
        {title}
      </Text>
    </div>
  );
};

const InactiveTabButton = ({ title, action, ...props }) => {
  return (
    <div className={Styles.container} onClick={action}>
      <Text size="m" width="medium" color="#9e9e9e">
        {title}
      </Text>
    </div>
  );
};

const TabButton = ({ title, action, status, ...props }) => {
  if (status) {
    return <ActiveTabButton title={title} action={action} />;
  }
  return <InactiveTabButton title={title} action={action} />;
};

export default TabButton;
