import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import AdditionalFacilityCounter from "@Molecule/AdditionalFacilityCounter";
import RadioButton from "@Atom/RadioButton";
import DatePicker from "@Atom/DatePicker";
import PackageCounter from "@Molecule/PackageCounter";
import DeveloperInformation from "@Molecule/DeveloperInformation";
import Button from "@Atom/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function PackageSimulator({ handleDone, ...props }) {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [paymentScheme, setPaymentScheme] = useState("");
  const [date, setDate] = useState(new Date());

  const closeModal = () => {
    document.body.style.removeProperty("overflow");
    handleDone();
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Text size="xl" width="bold" color="#0a0a0a">
          Atur Keberangkatan
        </Text>
        <div className={Styles.closeButton} onClick={closeModal}>
          <Icon icon="x-mark" size="16" className={Styles.icon} />
        </div>
      </div>
      <div className={Styles.body}>
        <DeveloperInformation />
        <div className={Styles.inputDateWrapper}>
          <Text size="l" width="medium" color="#000">
            Tanggal Keberangkatan & Ketersediaan
          </Text>
          <DatePicker value={date} setValue={setDate} disabled />
        </div>
        <div className={Styles.packageContainer}>
          <Text size="l" width="medium" color="#000">
            Paket
          </Text>
          <div className={Styles.packageOptions}>
            <PackageCounter
              name="Quad"
              price="Rp35.000.000"
              amount={value}
              setAmount={setValue}
            />
            <PackageCounter
              name="Triple"
              price="Rp36.500.000"
              amount={value}
              setAmount={setValue}
            />
            <PackageCounter
              name="Double"
              price="Rp38.000.000"
              amount={value}
              setAmount={setValue}
            />
          </div>
        </div>
        <div className={Styles.additionalContainer}>
          <Text size="l" width="medium" color="#000">
            Fasilitas Tambahan
          </Text>
          <div className={Styles.cards}>
            <AdditionalFacilityCounter
              label="Tambah Nama Paspor"
              price="Rp1.000.000"
              amount={value}
              setAmount={setValue}
            />
            <AdditionalFacilityCounter
              label="Perlengkapan & Heandling"
              price="Rp1.500.000"
              amount={value}
              setAmount={setValue}
            />
            <AdditionalFacilityCounter
              label="Tambah Nama Paspor"
              price="Rp1.000.000"
              amount={value}
              setAmount={setValue}
            />
            <AdditionalFacilityCounter
              label="Perlengkapan & Heandling"
              price="Rp1.500.000"
              amount={value}
              setAmount={setValue}
            />
            <AdditionalFacilityCounter
              label="Tambah Nama Paspor"
              price="Rp1.000.000"
              amount={value}
              setAmount={setValue}
            />
            <AdditionalFacilityCounter
              label="Perlengkapan & Heandling"
              price="Rp1.500.000"
              amount={value}
              setAmount={setValue}
            />
          </div>
        </div>
        <div className={Styles.paymentContainer}>
          <Text size="l" width="medium" color="#000">
            Pembayaran
          </Text>
          <div className={Styles.optionWrapper}>
            <Text size="m" width="medium" color="#000">
              Pembayaran Penuh
            </Text>
            <RadioButton
              isActive={paymentScheme === "full"}
              setIsActive={() => setPaymentScheme("full")}
              disabled
            />
          </div>
          <div className={Styles.optionWrapper}>
            <Text size="m" width="medium" color="#000">
              Pembayaran Uang Muka
            </Text>
            <RadioButton
              isActive={paymentScheme === "installment"}
              setIsActive={() => setPaymentScheme("installment")}
              disabled
            />
          </div>
        </div>
      </div>
      <div className={Styles.footer}>
        <div className={Styles.textWrapper}>
          <Text size="s" width="regular" color="#9e9e9e">
            Estimasi biaya
          </Text>
          <Text size="h4" width="bold" color="#0a0a0a">
            Rp-
          </Text>
        </div>
        <div className={Styles.buttonWrapper}>
          {/* <Button
            label="Lanjutkan Pesanan"
            onClick={() => navigate("/order")}
          /> */}
          <Button label="Lanjutkan Pesanan" onClick={() => null} disabled />
        </div>
      </div>
    </div>
  );
}
