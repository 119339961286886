import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import LOGO from "../../../assets/images/muslimnet-logo.png";
import Input from "@Atom/Input";
import Button from "@Atom/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorCard from "@Atom/ErrorCard";

const Register = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  return (
    <div className={Styles.container}>
      <div className={Styles.modal}>
        <div className={Styles.header}>
          <img src={LOGO} height={44} width={"fit-content"} alt="" />
        </div>
        <div className={Styles.body}>
          {error && (
            <div className={Styles.errorCardWrapper}>
              <ErrorCard errorMessage="Maaf nomor yang Anda masukkan tidak terdaftar" />
            </div>
          )}
          <div className={Styles.textWrapper}>
            <Text size="h3" width="bold" color="#0a0a0a">
              Daftar
            </Text>
            <Text size="m" width="regular" color="#9e9e9e">
              Lengkapi data anda untuk melanjutkan
            </Text>
          </div>
          <div className={Styles.formWrapper}>
            <div className={Styles.inputSection}>
              <Text size="m" width="medium" color="#000">
                Nama
              </Text>
              <div className={Styles.inputWrapper}>
                <Input
                  type="email"
                  placeholder="Nama Anda"
                  icon="user-circle"
                  error={error}
                  onFocus={() => setError(false)}
                />
              </div>
            </div>
            <div className={Styles.inputSection}>
              <Text size="m" width="medium" color="#000">
                Nomor WhatsApp
              </Text>
              <div className={Styles.inputWrapper}>
                <Input
                  type="email"
                  placeholder="Nama Anda"
                  icon="user-circle"
                  error={error}
                  onFocus={() => setError(false)}
                />
              </div>
            </div>
            <div className={Styles.inputSection}>
              <Text size="m" width="medium" color="#000">
                Email
              </Text>
              <div className={Styles.inputWrapper}>
                <Input
                  type="email"
                  placeholder="example@mail.com"
                  icon="envelope"
                  error={error}
                  onFocus={() => setError(false)}
                />
              </div>
            </div>
          </div>
          <Button label="Daftar" />
          <div className={Styles.registerNavWrapper}>
            <Text size="m" width="regular" color="#000">
              Sudah memiliki akun?
              <span className={Styles.span} onClick={() => navigate("/login")}>
                {" "}
                Masuk
              </span>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
