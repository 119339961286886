import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";
import useMediaQuery from "@Hooks/useMediaQuery";

const OtherFacilitiesSection = ({ facilities, ...props }) => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={Styles.otherFacilitiesContainer}>
      <Text size={isMobile ? "h4" : "h3"} width="bold" color="#000">
        Fasilitas Lainya
      </Text>
      <div className={Styles.facilities}>
        {facilities.map((facility, id) => (
          <div className={Styles.facility} key={id}>
            <Icon icon="check-circle" size="24" className={Styles.icon} />
            <Text size="m" width="regular" color="#0a0a0a">
              {facility}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherFacilitiesSection;
