import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";
import PICTURE from "../../../assets/images/saudi.jpg";
import TRAVEL_LOGO from "../../../assets/images/travel-logo.png";
import Button from "@Atom/Button";
import useMediaQuery from "@Hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";

const PortraitCard = ({ data, id, ...props }) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const {
    title,
    image,
    departureDate,
    availableSeat,
    travelName,
    travelLogo,
    price,
  } = data;
  return (
    <div className={Styles.container}>
      <img
        alt=""
        className={Styles.image}
        width="100%"
        height={"fit-content"}
        src={image}
      />
      <div className={Styles.informationWrapper}>
        <Text size={isMobile ? "l" : "h4"} width="medium" color="#000">
          {title}
        </Text>
        <div className={Styles.information}>
          <div className={Styles.mainInfos}>
            <div className={Styles.info}>
              <Icon icon="calendar" size="20" className={Styles.icon} />
              <Text size="m" width="regular" color="#000">
                {departureDate}
              </Text>
            </div>
            <div className={Styles.line}></div>
            <div className={Styles.info}>
              <Icon icon="user" size="20" className={Styles.icon} />
              <Text size="m" width="regular" color="#000">
                {`${availableSeat} tersedia`}
              </Text>
            </div>
          </div>
          <div className={Styles.travelInfos}>
            <img
              className={Styles.travelLogo}
              alt=""
              height="100%"
              width={50}
              src={travelLogo}
            />
            <div className={Styles.travelNameWrapper}>
              <Text size="s" width="regular" color="#9e9e9e">
                Travel by
              </Text>
              <Text size="m" width="medium" color="#000">
                {travelName}
              </Text>
            </div>
          </div>
        </div>
        <div className={Styles.pricingWrapper}>
          <div className={Styles.priceInfo}>
            <Text size="s" width="regular" color="#9e9e9e">
              Mulai dari
            </Text>
            <div className={Styles.numberWrapper}>
              <Text size="m" width="bold" color="#000000">
                {`Rp${price?.toLocaleString("id")}`}
              </Text>
              <Text size="s" width="regular" color="#9e9e9e">
                / Orang
              </Text>
            </div>
          </div>
          <div
            className={Styles.button}
            onClick={() => navigate(`/package/${id}`, { state: data })}
          >
            <Button label="Lihat Detail" />
          </div>
        </div>
      </div>
    </div>
  );
};

const LandscapeCard = ({ data, id, ...props }) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const {
    title,
    image,
    departureDate,
    availableSeat,
    travelName,
    travelLogo,
    price,
  } = data;
  return (
    <div className={Styles.landscapeContainer}>
      <img alt="" className={Styles.image} src={PICTURE} />
      <div className={Styles.informationWrapper}>
        <div className={Styles.top}>
          <Text size={isMobile ? "l" : "h4"} width="medium" color="#000">
            {title}
          </Text>
          <div className={Styles.information}>
            <div className={Styles.mainInfos}>
              <div className={Styles.info}>
                <Icon icon="calendar" size="20" className={Styles.icon} />
                <Text size="m" width="regular" color="#000">
                  {departureDate}
                </Text>
              </div>
              <div className={Styles.line}></div>
              <div className={Styles.info}>
                <Icon icon="user" size="20" className={Styles.icon} />
                <Text size="m" width="regular" color="#000">
                  {`${availableSeat} tersedia`}
                </Text>
              </div>
            </div>
            <div className={Styles.travelInfos}>
              <img className={Styles.travelLogo} alt="" src={TRAVEL_LOGO} />
              <div className={Styles.travelNameWrapper}>
                <Text size="s" width="regular" color="#9e9e9e">
                  Travel by
                </Text>
                <Text size="m" width="medium" color="#000">
                  Jejak Imani Travel
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.pricingWrapper}>
          <div className={Styles.priceInfo}>
            <Text size="s" width="regular" color="#9e9e9e">
              Mulai dari
            </Text>
            <div className={Styles.numberWrapper}>
              <Text size="m" width="bold" color="#000000">
                Rp38.000.000
              </Text>
              <Text size="s" width="regular" color="#9e9e9e">
                / Orang
              </Text>
            </div>
          </div>
          <div
            className={Styles.button}
            onClick={() => navigate(`/package/${id}`)}
          >
            <Button label="Lihat Detail" />
          </div>
        </div>
      </div>
    </div>
  );
};

const PackageCard = ({ data, id, type, ...props }) => {
  if (type === "column") {
    return <PortraitCard data={data} id={id} />;
  }
  if (type === "row") {
    return <LandscapeCard data={data} id={id} />;
  }
  return <PortraitCard data={data} id={id} />;
};

export default PackageCard;
