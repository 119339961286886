import Styles from "./style.module.scss";
// import AffiliatorAd from "@Molecule/AffiliatorAd";
import Header from "./header";
import OtherFacilitiesSection from "./otherFacilities";
import Description from "./description";
import Poster from "./poster";
import Itenerary from "./itenerary";
import PriceSimulator from "@Molecule/PriceSimulator";
import ResponsivePriceSimulator from "./responsivePriceSimulator";
import useMediaQuery from "@Hooks/useMediaQuery";
import PackageSimulator from "@Molecule/Modal/PackageSimulator";
import Modal from "@Atom/Modal";
// import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const DetailPackage = ({
  isMobile,
  modalOpen,
  setModalOpen,
  route,
  ...props
}) => {
  // const location = useLocation();
  // const data = location.state;
  let { id } = useParams();

  // const {
  //   title,
  //   image,
  //   travelName,
  //   travelLogo,
  //   description,
  //   list,
  //   registeredNumber,
  //   additional,
  //   link,
  // } = data;

  const packages = [
    {
      id: 1,
      title: "Umroh Syawal",
      link: "http://localhost:3000/package/1",
      departureDate: "17 April 2024",
      availableSeat: 40,
      registeredNumber: "SK PPIU : 10062200456820002",
      travelName: "Arima Wisata",
      travelLogo:
        "https://ucarecdn.com/c351229b-03fd-446f-bed1-bba329c6813f/LogoSekunder.png",
      price: 29900000,
      image:
        "https://ucarecdn.com/59c93f73-c3b5-423a-9d55-8ed8a7e4c44c/WhatsAppImage20240204at100651.jpeg",
      description:
        "Dilaksanakan pada bulan Syawal tepatnya tanggal 17 April 2024. Jamaah berangkat menggunakan pesawat Etihad Airways. Rute penerbangan Jakarta - Abu Dhabi - Jeddah. Jamaah menginap di Madinah selama 3 Malam dengan hotel Emaar Hotel/Setaraf sedangkan di Mekkah menginap di Anjum/Setaraf selama 4 Malam.",
      list: [
        {
          id: 1,
          desc: `Jakarta - Abu Dhabi - Jeddah: Menggunakan Seragam Batik Arima dan Bawahan Bebas, Persiapan keberangkatan, berkumpul di Bandara Soekarno Hatta, menuju Bandara Soetta untuk proses check-in, bagasi, imigrasi dan boarding, Transit menuju Abu Dhabi, dengan maskapai Etihad Airways, In syaa Allah dari Abu Dhabi melanjutkan perjalanan menuju Jeddah, proses imigrasi.`,
        },
        {
          id: 2,
          desc: `Madinah: Perjalanan menuju Madinah dengan Bus, In syaa Allah tiba di Madinah, check-in Hotel, Makan pagi, siang dan malam di Hotel, Menjelang Dzuhur: Ziarah dalam, yaitu sholat sunnah dan berdoa di Raudah serta ziarah ke makam Rasulullah, Abu Bakar AsShidiq dan Umar Ibnu Khatab, Setelah Ashaar: Ziarah ke makam Baqi, Memperbanyak ibadah di masjid Nabawi.`,
        },
        {
          id: 3,
          desc: `Madinah: Makan pagi, siang dan malam di Hotel, Pagi: Ziarah luar yaitu keliling kota Madinah mengunjungi masjid Quba, masjid Qiblatain, Jabal Uhud dan Kebun Kurma, Setelah Ashaar: Pemantapan manasik Umroh, oleh Syaikh Firdaus, Memperbanyak ibadah di masjid Nabawi.`,
        },
        {
          id: 4,
          desc: `Madinah-Makkah-Umroh:
          Makan pagi dan siang di hotel Madinah, Sholat Dzuhur dan Ashaar, jamak takdim, KELUARKAN Baju Ihram untuk melaksanakan Umroh Setelah sholat Dzuhur dan makan siang, check-out hotel dan perjalanan menuju Makkah, Mengambil miqat Umroh di Bir Ali, Tiba di Makkah, check-in hotel, makan malam dan sholat jamak Maghrib dan Isya, Prosesi Umroh.`,
        },
        {
          id: 5,
          desc: `Makkah: Makan pagi, siang dan malam di Hotel, Free Program, Memperbanyak ibadah di Masjidil Haram.`,
        },
        {
          id: 6,
          desc: `Makkah: Makan pagi, siang dan malam di Hotel, Sholat Jumat di Masjidil Haram, Setelah Ashaar: Kajian Islam Ilmiyah, oleh Syaikh Firdaus, Free Program, Memperbanyak ibadah di Masjidil Haram.`,
        },
        {
          id: 7,
          desc: `Makkah: Menggunakan Seragam Arima dan Bawahan Bebas, Makan pagi, siang dan malam di Hotel, Pagi: Ziarah luar yaitu keliling kota Makkah menuju Jabal Tsur, Jabal Nur, Jabal Rahmah, Arafah dan Mina.`,
        },
        {
          id: 8,
          desc: `Makkah - Jeddah - Abu Dhabi: Makan pagi di hotel, Setelah sarapan melaksanakan Tawaf Wada, Kemudian persiapan check-out hotel. Kemudian lanjutkan Perjalanan dari Makaah menuju kota Jeddah menggunakan Bus, Setibanya di Jeddah, transit menuju Abu Dhabi setelah itu dari Abu Dhabi menuju Jakarta.`,
        },
        {
          id: 9,
          desc: `Abu Dhabi - Jakarta:
          Take off menuju Jakarta, maskapai Etihad Airways, Insyaa Allah tiba di Jakarta, Pengambilan air zam-zam di bandara Soetta.`,
        },
      ],
      additional: [
        "Tiket Pesawat",
        "Hotel",
        "Makan Full Board",
        "Bus",
        "Muthawif dan Tour Leader",
        "Visa Umroh",
        "Asuransi Perjalanan",
        "Handling Bandara",
        "Perlengkapan senilai Rp. 1.500.000,-",
      ],
    },
    {
      id: 2,
      title: "Umroh City Tour Jeddah",
      link: "http://localhost:3000/package/2",
      departureDate: "17 April 2024",
      availableSeat: 40,
      registeredNumber: "SK PPIU : 10062200456820002",
      travelName: "Arima Wisata",
      travelLogo:
        "https://ucarecdn.com/c351229b-03fd-446f-bed1-bba329c6813f/LogoSekunder.png",
      price: 33200000,
      image:
        "https://ucarecdn.com/d2a38f61-4bc0-449f-bd0b-29aec57b0b19/1706513474836image.jpeg",
      description: `Umroh Plus City Tour Jeddah 9 HARI Murah & Berlimpah Pahala Siap mengabadikan setiap momen perjalanan umroh sahabat yang berkesan dan penuh makna. ✈️ Saudia Airlines 🗓️ 17 April 2024 🏨 Hotel ⭐⭐⭐⭐ Madinah 3N Emaar Elite / Concorrde (Setaraf) Mekkah 4N Mariot Jabal Omar / Anjum / Double Tree (Setaraf) Harga Quad : Rp. 33.200.000 Triple : Rp. 34.800.000 Double: Rp. 37.700.000.`,
      list: [
        {
          id: 1,
          desc: `Jakarta - Abu Dhabi - Jeddah: Menggunakan Seragam Batik Arima dan Bawahan Bebas, Persiapan keberangkatan, berkumpul di Bandara Soekarno Hatta, menuju Bandara Soetta untuk proses check-in, bagasi, imigrasi dan boarding, Transit menuju Abu Dhabi, dengan maskapai Etihad Airways, In syaa Allah dari Abu Dhabi melanjutkan perjalanan menuju Jeddah, proses imigrasi.`,
        },
        {
          id: 2,
          desc: `Madinah: Perjalanan menuju Madinah dengan Bus, In syaa Allah tiba di Madinah, check-in Hotel, Makan pagi, siang dan malam di Hotel, Menjelang Dzuhur: Ziarah dalam, yaitu sholat sunnah dan berdoa di Raudah serta ziarah ke makam Rasulullah, Abu Bakar AsShidiq dan Umar Ibnu Khatab, Setelah Ashaar: Ziarah ke makam Baqi, Memperbanyak ibadah di masjid Nabawi.`,
        },
        {
          id: 3,
          desc: `Madinah: Makan pagi, siang dan malam di Hotel, Pagi: Ziarah luar yaitu keliling kota Madinah mengunjungi masjid Quba, masjid Qiblatain, Jabal Uhud dan Kebun Kurma, Setelah Ashaar: Pemantapan manasik Umroh, oleh Syaikh Firdaus, Memperbanyak ibadah di masjid Nabawi.`,
        },
        {
          id: 4,
          desc: `Madinah-Makkah-Umroh:
          Makan pagi dan siang di hotel Madinah, Sholat Dzuhur dan Ashaar, jamak takdim, KELUARKAN Baju Ihram untuk melaksanakan Umroh Setelah sholat Dzuhur dan makan siang, check-out hotel dan perjalanan menuju Makkah, Mengambil miqat Umroh di Bir Ali, Tiba di Makkah, check-in hotel, makan malam dan sholat jamak Maghrib dan Isya, Prosesi Umroh.`,
        },
        {
          id: 5,
          desc: `Makkah: Makan pagi, siang dan malam di Hotel, Free Program, Memperbanyak ibadah di Masjidil Haram.`,
        },
        {
          id: 6,
          desc: `Makkah: Makan pagi, siang dan malam di Hotel, Sholat Jumat di Masjidil Haram, Setelah Ashaar: Kajian Islam Ilmiyah, oleh Syaikh Firdaus, Free Program, Memperbanyak ibadah di Masjidil Haram.`,
        },
        {
          id: 7,
          desc: `Makkah: Menggunakan Seragam Arima dan Bawahan Bebas, Makan pagi, siang dan malam di Hotel, Pagi: Ziarah luar yaitu keliling kota Makkah menuju Jabal Tsur, Jabal Nur, Jabal Rahmah, Arafah dan Mina.`,
        },
        {
          id: 8,
          desc: `Makkah - Jeddah - Abu Dhabi: Makan pagi di hotel, Setelah sarapan melaksanakan Tawaf Wada, Kemudian persiapan check-out hotel. Kemudian lanjutkan Perjalanan dari Makaah menuju kota Jeddah menggunakan Bus, Setibanya di Jeddah, transit menuju Abu Dhabi setelah itu dari Abu Dhabi menuju Jakarta.`,
        },
        {
          id: 9,
          desc: `Abu Dhabi - Jakarta:
          Take off menuju Jakarta, maskapai Etihad Airways, Insyaa Allah tiba di Jakarta, Pengambilan air zam-zam di bandara Soetta.`,
        },
      ],
      additional: [
        "Tiket Pesawat",
        "Hotel",
        "Makan Full Board",
        "Bus",
        "Muthawif dan Tour Leader",
        "Visa Umroh",
        "Asuransi Perjalanan",
        "Handling Bandara",
        "Perlengkapan senilai Rp. 1.500.000,-",
      ],
    },
    {
      id: 3,
      title: "Umroh Syawal - 2x Jum'at 12 Hari",
      link: "http://localhost:3000/package/3",
      departureDate: "25 April 2024",
      availableSeat: 40,
      registeredNumber: "SK PPIU : 10062200456820002",
      travelName: "Arima Wisata",
      travelLogo:
        "https://ucarecdn.com/c351229b-03fd-446f-bed1-bba329c6813f/LogoSekunder.png",
      price: 39900000,
      image:
        "https://ucarecdn.com/29004f6a-6277-47bd-b2f2-dddb00c58444/429013783_891834295975225_7749902140727667248_n.webp",
      description:
        "Umroh Syawal - 2x Jum'at 12 Hari siap mengabadikan setiap momen perjalanan umroh sahabat yang berkesan dan penuh makna. ✈️ OMAN Air 🗓️ 25 April 2024 🏨 Hotel ⭐⭐⭐⭐⭐ Madinah 3N Emaar Royal / setaraf Mekkah 4N Marwa Rotana / setaraf Harga Quad : Rp. 39.900.000 Triple : Rp. 43.300.000 Double: Rp. 47.000.000 Sudah termasuk perlengkapan senilai Rp. 1.500.000.",
      list: [
        {
          id: 1,
          desc: ` Jakarta - Muscat - Jeddah - Madinah: Berkumpul di Bandara International Soekarno Hatta, Jakarta 3 (tiga) jam sebelum keberangkatan untuk penerbangan dari Jakarta menuju Muscat, Oman menggunakan Oman Air. Setibanya tiba di Bandara Muscat, Jamaah melanjutkan perjalanan menuju Jeddah menggunakan Oman Air. Setibanya di Jeddah, Jamaah melanjutkan perjalanan menuju Madinah menggunakan Bus.`,
        },
        {
          id: 2,
          desc: `Madinah: Setelah sarapan pagi, acara bebas dengan memperbanyak Ibadah di Masjid Nabawi, sore hari jamaah akan diajak mengunjungi Masjid Ghamama serta Maqam Baqi yang berlokasi di Area Masjid Nabawi, Setelah Sholat Isya dan makan malam, berziarah ke Makam Rasullulah SAW & Para Sahabat dengan di dampingi Mutawif wanita dan laki–laki kemudian melaksanakan shalat sunah dan berdoa di Raudah (Jadwal bisa berubah sesuai pada pendaftaran nusuk). Setelah itu memperbanyak Ibadah di Masjid Nabawi. (Free Program). (Makan Pagi, Makan Siang & Makan Malam).`,
        },
        {
          id: 3,
          desc: `Madinah: Setelah sarapan pagi, memperbanyak Ibadah di Masjid Nabawi. (Free Program). (Makan Pagi, Makan Siang & Makan Malam).`,
        },
        {
          id: 4,
          desc: `Madinah:
          Setelah sarapan pagi persiapan untuk Ziarah Kota Madinah. Ziarah mengunjungi Jabal Uhud, Masjid Quba, Percetakan Al-Qur’an dan Pasar Kurma. Setelah itu kembali ke hotel dan kembali memperbanyak ibadah di Masjid Nabawi. (Makan Pagi, Makan Siang & Makan Malam).`,
        },
        {
          id: 5,
          desc: ` Madinah – Makkah (Kereta cepat/Bus Madinah – Makkah): Setelah sarapan pagi, mandi sunnah ihram dan persiapan untuk cek out. Setelah Sholat Dzuhur dan makan siang persiapan berangkat menuju ke Makkah Menuju Stasiun Kereta Cepat/Bus. Tiba di Makkah, langsung check in hotel untuk menyimpan barang-barang dan makan malam, kemudian melaksanakan ibadah Umroh. Setelah ibadah umroh selesai jamaah kembali ke hotel untuk beristirahat. (Makan Pagi, Makan Siang & Makan Malam).`,
        },
        {
          id: 6,
          desc: `Makkah: Setelah sarapan pagi, memperbanyak Ibadah di Masjidil Haram. (Free Program). (Makan Pagi, Makan Siang & Makan Malam).`,
        },
        {
          id: 7,
          desc: `Makkah - Thaif: Setelah sarapan pagi, persiapan Ziarah Kota Makkah. Ziarah mengunjungi Padang Arafah, Jabal Rahmah, Muzdalifah, Mina dan Jabal Tsur. Kemudian berakhir di Ji’ronah (bagi yang akan melaksanakan umroh kedua Silahkan untuk mengambil miqot) bagi yang tidak mengikuti umrah kedua memperbanyak Ibadah di Masjidil Haram. ( Makan Pagi, Makan Siang & Makan Malam ).`,
        },
        {
          id: 8,
          desc: `Makkah - Muscat: Setelah sarapan pagi, persiapan Ziarah Kota Thaif Ziarah mengunjungi Masjid Abdullah Bin Abbas, Masjid Qu, Masjid Addas. Kemudian berakhir di Pasar Buah dan Rose Factory kemudian kembali ke hotel dan memperbanyak Ibadah di Masjidil Haram. (Makan Pagi, Makan Siang & Makan Malam).`,
        },
        {
          id: 9,
          desc: `Makkah:
          Setelah sarapan pagi, memperbanyak Ibadah di Masjidil Haram. (Free Program). (Makan Pagi, Makan Siang & Makan Malam).`,
        },
        {
          id: 10,
          desc: `Makkah: Setelah sarapan pagi, memperbanyak Ibadah di Masjidil Haram. (Free Program). (Makan Pagi, Makan Siang & Makan Malam).`,
        },
        {
          id: 11,
          desc: `Makkah - Jeddah: Setelah Sholat Subuh dan Thawaf Wada’ persiapan untuk cek out hotel.Jamaah bertolak menuju Jeddah dan City Tour, Mengunjungi Corniche, Masjid Qishash dan Makam Siti Hawa, kemudian Setelah itu 4 jam sebelum take off jamaah menuju airport Jeddah untuk keberangkatan ke Jeddah - Muscat. Dari Muscat Jamaah melanjutkan perjalanan menuju Jakarta (Makan Pagi, Makan Siang & Makan Malam).`,
        },
        {
          id: 12,
          desc: `Jakarta:
          Tiba di Jakarta dan Insha Allah membawa “Umrah Maqbullah“ dan kenangan yang indah bersama ARIMA WISATA.Terimakasih atas keikutsertaan anda dalam memilih ARIMA WISATA sebagai Sahabat Perjalanan Ibadah Umroh anda.`,
        },
      ],
      additional: [
        "Tiket Pesawat",
        "Hotel",
        "Makan Full Board",
        "Bus",
        "Muthawif dan Tour Leader",
        "Visa Umroh",
        "Asuransi Perjalanan",
        "Handling Bandara",
      ],
    },
    {
      id: 4,
      title: "Umroh Plus Turki",
      link: "http://localhost:3000/package/4",
      departureDate: "18 April 2024",
      availableSeat: 40,
      registeredNumber: "SK PPIU : 10062200456820002",
      travelName: "Arima Wisata",
      travelLogo:
        "https://ucarecdn.com/c351229b-03fd-446f-bed1-bba329c6813f/LogoSekunder.png",
      price: 38700000,
      image:
        "https://ucarecdn.com/b4a77264-a282-4ac0-9543-d355afef6bcd/429901987_1837675103362779_1305127469075865439_n.webp",
      description:
        "Umroh Plus Turki 11 Hari siap mengabadikan setiap momen perjalanan umroh sahabat yang berkesan dan penuh makna. ✈️ Saudia Airlines 🗓️ 18 April 2024 🏨 Hotel ⭐⭐⭐⭐⭐ Madinah 3N Emaar Elite / Grand Plaza / setaraf Mekkah 4N Azka Shafa / Anjum / setaraf Harga Quad : Rp. 38.700.000 Triple : Rp. 40.300.000 Double: Rp. 42.900.000.",
      list: [
        {
          id: 1,
          desc: `Jakarta - Jeddah: Berkumpul di Lounge Aljazeerah terminal 3 pukul 12.00 WIB,kemudian bersama menuju Soekarno-Hatta International Airport Terminal 3 untuk proses check-in, bagasi, dan imigrasi. Selanjutnya bersama - sama masuk menuju ke boarding lounge sambil menunggu waktu keberangkatan. Jamaah dijadwalkan take off pukul 17.30 WIB dengan menggunakan pesawat Saudi Airlines SV819 dan tiba di Jeddah pukul 23.15 AST (Waktu Saudi).`,
        },
        {
          id: 2,
          desc: `Jeddah - Istanbul: Setelah shalat subuh, Jemaah bersiap melanjutkan perjalanan menuju Istanbul dengan maskapai Saudi Airlines. No penerbangan SV265 take Off Pukul 06.20 dan tiba di Istanbul pukul 10.30 TRT (waktu turki). Setelah sarapan pagi, perjalanan akan dilanjutkan City Tour kota Bursa mengunjungi: Turkish Delight shop. Sholat Dzuhur & Ashar di Jama Qoshor, makan siang di Restaurant Efezade. Lalu dilanjutkan mengunjungi Grand Mosque-Green Mosque & Tomb, Silk Market. Selesai tour di Bursa, kemudian transfer to Istanbul. Makan malam di Restaurant mehmetcik.
          Sholat Magrib dan Isya di Jama Qoshor, transfer Hotel Istanbul, Istirahat.`,
        },
        {
          id: 3,
          desc: `Istanbul - Madinah: Setelah sarapan pagi Jemaah Check out dan bersiap menuju Istanbul Ataturk Internatinal Airport untuk bersama-sama bersiap menuju Madinah dengan menggunakan pesawat Saudi Airlines yang dijadwalkan take off pukul 13.45 TRT (Waktu Turki). Tiba di Madinah pukul 17.20 AST (Waktu Saudi Arabia). Setelah proses imigrasi dan bagasi selesai, jamaah akan diantarkan menuju hotel Madinah untuk beristirahat dan menginap.`,
        },
        {
          id: 4,
          desc: `Madinah: Pagi hari setelah sarapan, jamaah bersiap untuk melaksanakan ziarah Kota Madinah. Mengunjungi Jabaluhud, MakamBaqi, melewati Masjid Kiblatain, Masjid Quba, KebunKurma. Memperbanyak Ibadah di Masjid Nabawi.(MP/MS/MM) Note: Ziarah apabila dapat dilakukan.`,
        },
        {
          id: 5,
          desc: `Madinah - Makkah: Siang hari bertolak ke Makkah dan singgah di Bi’ir Ali untuk mengambil miqot. Setibanya di Makkah, jamaah akan dituntun untuk melaksanakan Umroh Pertama.`,
        },
        {
          id: 6,
          desc: `Makkah: Memperbanyak Ibadah di Masjidil Haram.`,
        },
        {
          id: 7,
          desc: `Makkah: Setelah sarapan pagi di hotel, kemudian para jamaah bersiap untuk mengikuti Ziarah Kota Makkah: Melewati Jabal Tsur, Jabal Nur, Jabal Rahmah, Arafah, dan Mina. Note: Ziarah apabila dapat dilakukan.`,
        },
        {
          id: 8,
          desc: `Makkah: Memperbanyak Ibadah di Masjidil Haram.`,
        },
        {
          id: 9,
          desc: `Makkah: Memperbanyak Ibadah di Masjidil Haram.`,
        },
        {
          id: 10,
          desc: `Setelah makan siang, jamaah bersiap checkout kemudian bertolak menuju King Abdul Aziz International Airport untuk persiapan penerbangan menuju Jakarta menggunakan Saudi Airlines SV816 yang dijadwalkan take off pukul 17.25 AST (Waktu Saudi Arabia) dan tiba di Bandara Soekarno Hatta Pukul 07.35 + 1(next day).`,
        },
      ],
      additional: [
        "Tiket Pesawat",
        "Hotel",
        "Makan Full Board",
        "Bus",
        "Muthawif dan Tour Leader",
        "Visa Umroh",
        "Asuransi Perjalanan",
        "Handling Bandara",
        "Perlengkapan senilai Rp. 1.500.000,-",
      ],
    },
  ];

  const packageSelector = () => {
    let selectedTrip = null;
    packages.forEach(function (trip) {
      // console.log(trip, id, "MG 1");
      if (trip?.id === +id) {
        console.log(trip, "MG 2");
        selectedTrip = trip;
      }
    });
    // console.log(selected, "SELECTED");
    return selectedTrip;
  };

  const priceSimulatorHidden = useMediaQuery("(max-width: 1287px)");
  console.log(packageSelector(), "LINK");

  return (
    <div className={Styles.container}>
      {modalOpen && (
        <Modal handleDone={() => setModalOpen(false)} isOpen={modalOpen}>
          <PackageSimulator handleDone={() => setModalOpen(false)} />
        </Modal>
      )}
      <div className={Styles.affiliatorAdWrapper}>{/* <AffiliatorAd /> */}</div>
      <ResponsivePriceSimulator setModalOpen={setModalOpen} />
      <Header
        title={packageSelector()?.title}
        logo={packageSelector()?.travelLogo}
        travelName={packageSelector()?.travelName}
        registerNumber={packageSelector()?.registeredNumber}
        link={packageSelector()?.link}
      />
      <div className={Styles.packageInformation}>
        <div className={Styles.information}>
          <Poster image={packageSelector()?.image} />
          <Description
            title="Deskripsi"
            description={packageSelector()?.description}
          />
          <Itenerary list={packageSelector()?.list} />
          <OtherFacilitiesSection facilities={packageSelector()?.additional} />
        </div>
        {!priceSimulatorHidden && (
          <div className={Styles.priceSimulatorWrapper}>
            <PriceSimulator />
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailPackage;
