import React from "react";
import { View } from "@react-pdf/renderer";
import Styles from "./styles";
import PdfText from "@Atom/PdfText";

const Transfer = () => {
  const divider = 1.5;

  return (
    <View style={Styles.footer}>
      <View style={Styles.footerLeft}>
        <PdfText size={16 / divider} color="#fff" type="bold">
          Bank Transfer
        </PdfText>
        <View style={Styles.paymentMethodWrapper}>
          <View style={Styles.sectionWrapper}>
            <View style={Styles.section}>
              <PdfText size={14 / divider} color="#ededed">
                Bank
              </PdfText>
            </View>
            <PdfText size={14 / divider} color="#fff">
              : BNI
            </PdfText>
          </View>
          <View style={Styles.sectionWrapper}>
            <View style={Styles.section}>
              <PdfText size={14 / divider} color="#ededed">
                No Rekening
              </PdfText>
            </View>
            <PdfText size={14 / divider} color="#fff">
              : 1437956326
            </PdfText>
          </View>
          <View style={Styles.sectionWrapper}>
            <View style={Styles.section}>
              <PdfText size={14 / divider} color="#ededed">
                Nama
              </PdfText>
            </View>
            <PdfText size={14 / divider} color="#fff">
              : Alfin Wira Yuda
            </PdfText>
          </View>
        </View>
      </View>
      <View style={Styles.footerRight}>
        <View style={Styles.footerTotalCostWrapper}>
          <PdfText size={14 / divider} color="#ededed">
            Total pembayaran
          </PdfText>
          <PdfText size={32 / divider} color="#fff" type="bold">
            Rp183.7000.000
          </PdfText>
        </View>
        <PdfText size={14 / divider} color="#fafafa">
          Harap perhatikan hingga 3 digit terakhir
        </PdfText>
      </View>
    </View>
  );
};

const BSI = () => {
  const divider = 1.5;

  return (
    <View style={Styles.footer}>
      <View style={Styles.footerLeft}>
        <PdfText size={16 / divider} color="#fff" type="bold">
          Virtual Account
        </PdfText>
        <View style={Styles.paymentMethodWrapper}>
          <View style={Styles.sectionWrapper}>
            <View style={Styles.section}>
              <PdfText size={14 / divider} color="#ededed">
                Bank
              </PdfText>
            </View>
            <PdfText size={14 / divider} color="#fff">
              : BSI
            </PdfText>
          </View>
          <View style={Styles.sectionWrapper}>
            <View style={Styles.section}>
              <PdfText size={14 / divider} color="#ededed">
                Nomor virtual account
              </PdfText>
            </View>
            <PdfText size={14 / divider} color="#fff">
              : 812777389478824
            </PdfText>
          </View>
        </View>
      </View>
      <View style={Styles.footerRight}>
        <View style={Styles.footerTotalCostWrapper}>
          <PdfText size={14 / divider} color="#ededed">
            Total pembayaran
          </PdfText>
          <PdfText size={32 / divider} color="#fff" type="bold">
            Rp183.7000.000
          </PdfText>
        </View>
        <PdfText size={14 / divider} color="#fafafa">
          Harap perhatikan hingga 3 digit terakhir
        </PdfText>
      </View>
    </View>
  );
};

const Mandiri = () => {
  const divider = 1.5;

  return (
    <View style={Styles.footer}>
      <View style={Styles.footerLeft}>
        <PdfText size={16 / divider} color="#fff" type="bold">
          Virtual Account
        </PdfText>
        <View style={Styles.paymentMethodWrapper}>
          <View style={Styles.sectionWrapper}>
            <View style={Styles.section}>
              <PdfText size={14 / divider} color="#ededed">
                Bank
              </PdfText>
            </View>
            <PdfText size={14 / divider} color="#fff">
              : Bank Mandiri
            </PdfText>
          </View>
          <View style={Styles.sectionWrapper}>
            <View style={Styles.section}>
              <PdfText size={14 / divider} color="#ededed">
                Nomor virtual account
              </PdfText>
            </View>
            <PdfText size={14 / divider} color="#fff">
              : 812777389478824
            </PdfText>
          </View>
        </View>
      </View>
      <View style={Styles.footerRight}>
        <View style={Styles.footerTotalCostWrapper}>
          <PdfText size={14 / divider} color="#ededed">
            Total pembayaran
          </PdfText>
          <PdfText size={32 / divider} color="#fff" type="bold">
            Rp183.7000.000
          </PdfText>
        </View>
        <PdfText size={14 / divider} color="#fafafa">
          Harap perhatikan hingga 3 digit terakhir
        </PdfText>
      </View>
    </View>
  );
};

const BCA = () => {
  const divider = 1.5;

  return (
    <View style={Styles.footer}>
      <View style={Styles.footerLeft}>
        <PdfText size={16 / divider} color="#fff" type="bold">
          Virtual Account
        </PdfText>
        <View style={Styles.paymentMethodWrapper}>
          <View style={Styles.sectionWrapper}>
            <View style={Styles.section}>
              <PdfText size={14 / divider} color="#ededed">
                Bank
              </PdfText>
            </View>
            <PdfText size={14 / divider} color="#fff">
              : Bank BCA
            </PdfText>
          </View>
          <View style={Styles.sectionWrapper}>
            <View style={Styles.section}>
              <PdfText size={14 / divider} color="#ededed">
                Nomor virtual account
              </PdfText>
            </View>
            <PdfText size={14 / divider} color="#fff">
              : 812777389478824
            </PdfText>
          </View>
        </View>
      </View>
      <View style={Styles.footerRight}>
        <View style={Styles.footerTotalCostWrapper}>
          <PdfText size={14 / divider} color="#ededed">
            Total pembayaran
          </PdfText>
          <PdfText size={32 / divider} color="#fff" type="bold">
            Rp183.7000.000
          </PdfText>
        </View>
        <PdfText size={14 / divider} color="#fafafa">
          Harap perhatikan hingga 3 digit terakhir
        </PdfText>
      </View>
    </View>
  );
};

const BNI = () => {
  const divider = 1.5;

  return (
    <View style={Styles.footer}>
      <View style={Styles.footerLeft}>
        <PdfText size={16 / divider} color="#fff" type="bold">
          Virtual Account
        </PdfText>
        <View style={Styles.paymentMethodWrapper}>
          <View style={Styles.sectionWrapper}>
            <View style={Styles.section}>
              <PdfText size={14 / divider} color="#ededed">
                Bank
              </PdfText>
            </View>
            <PdfText size={14 / divider} color="#fff">
              : Bank BNI
            </PdfText>
          </View>
          <View style={Styles.sectionWrapper}>
            <View style={Styles.section}>
              <PdfText size={14 / divider} color="#ededed">
                Nomor virtual account
              </PdfText>
            </View>
            <PdfText size={14 / divider} color="#fff">
              : 812777389478824
            </PdfText>
          </View>
        </View>
      </View>
      <View style={Styles.footerRight}>
        <View style={Styles.footerTotalCostWrapper}>
          <PdfText size={14 / divider} color="#ededed">
            Total pembayaran
          </PdfText>
          <PdfText size={32 / divider} color="#fff" type="bold">
            Rp183.7000.000
          </PdfText>
        </View>
        <PdfText size={14 / divider} color="#fafafa">
          Harap perhatikan hingga 3 digit terakhir
        </PdfText>
      </View>
    </View>
  );
};

const Footer = ({ type, ...props }) => {
  if (type === "transfer") {
    return <Transfer />;
  }
  if (type === "bsi") {
    return <BSI />;
  }
  if (type === "mandiri") {
    return <Mandiri />;
  }
  if (type === "bca") {
    return <BCA />;
  }
  if (type === "bni") {
    return <BNI />;
  }
};

export default Footer;
