import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import { useState, useEffect } from "react";
import useMediaQuery from "@Hooks/useMediaQuery";

const Header = ({
  title,
  logo,
  travelName,
  registerNumber,
  link,
  ...props
}) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal) {
      const toRef = setTimeout(() => {
        setShowModal(false);
        clearTimeout(toRef);
      }, 1000);
    }
  }, [showModal]);

  return (
    <div className={Styles.header}>
      <div className={Styles.titleContainer}>
        <Text size={isMobile ? "h4" : "h2"} width="bold" color="#000">
          {title}
        </Text>
        <div className={Styles.travelInfoWrapper}>
          <div className={Styles.travelLogo}>
            <img
              src={logo}
              alt=""
              width={"100%"}
              height={"100%"}
              style={{ borderRadius: 5 }}
            />
          </div>
          <div className={Styles.travelInfo}>
            <Text size={isMobile ? "s" : "m"} width={"medium"} color="#000">
              {travelName}
            </Text>
            <Text
              size={isMobile ? "xs" : "s"}
              width={"regular"}
              color="#9e9e9e"
            >
              {registerNumber}
            </Text>
          </div>
        </div>
      </div>
      <div
        className={Styles.shareWrapper}
        onClick={() =>
          navigator.clipboard.writeText(link).then(() => {
            setShowModal(true);
          })
        }
      >
        <Icon icon="share" size="20" className={Styles.icon} />
        <span className={Styles.shareText}>Bagikan</span>
      </div>
      <div
        className={
          showModal
            ? Styles.successPopupWrapper
            : Styles.inactiveSuccessPopupWrapper
        }
      >
        <div className={Styles.popup}>
          <Text size="m" width="medium" color="#fafafa">
            Berhasil disalin
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Header;
