import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import Button from "@Atom/Button";
import { useNavigate } from "react-router-dom";

const ResponsivePriceSimulator = ({ setModalOpen, ...props }) => {
  const navigate = useNavigate();

  return (
    <div className={Styles.mobilePriceSimulator}>
      <div className={Styles.left}>
        <Text size="s" width="regular" color="#9e9e9e">
          Estimasi Biaya
        </Text>
        <Text size="h4" width="bold" color="#0a0a0a">
          Rp-
        </Text>
        <div className={Styles.textLink} onClick={() => setModalOpen(true)}>
          Atur Keberangkatan
        </div>
      </div>
      <div className={Styles.right}>
        {/* <Button label="Lanjutkan Pesanan" onClick={() => navigate("/order")} /> */}
        <Button label="Lanjutkan Pesanan" onClick={() => null} disabled />
      </div>
    </div>
  );
};

export default ResponsivePriceSimulator;
