import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import DatePicker from "@Atom/DatePicker";
import { useEffect, useState } from "react";
import Button from "@Atom/Button";
import RadioButton from "@Atom/RadioButton";
import AdditionalFacilityCounter from "@Molecule/AdditionalFacilityCounter";
import DeveloperInformation from "@Molecule/DeveloperInformation";
import PackageCounter from "@Molecule/PackageCounter";
import { useNavigate } from "react-router-dom";
import Icon from "@Atom/Icon";

const PriceSimulator = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [paymentScheme, setPaymentScheme] = useState("");
  const [priceEstimation, setPriceEstimation] = useState(0);

  const packageList = [
    {
      id: 1,
      name: "Quad",
      price: 35000000,
      packageQty: 0,
    },
    {
      id: 2,
      name: "Triple",
      price: 36500000,
      packageQty: 0,
    },
    {
      id: 3,
      name: "Double",
      price: 38000000,
      packageQty: 0,
    },
  ];

  const additionalList = [
    {
      id: 1,
      name: "Tambah Nama Paspor",
      price: 1000000,
      packageQty: 0,
    },
    {
      id: 2,
      name: "Perlengkapan & Heandling",
      price: 1500000,
      packageQty: 0,
    },
  ];

  const [cart, setCart] = useState(packageList);

  const handleDecrement = (itemId, type) => {
    setCart((cart) =>
      cart.map((item) =>
        itemId === item.id ? { ...item, packageQty: item.packageQty - 1 } : item
      )
    );
  };

  const handleIncrement = (itemId, type) => {
    setCart((cart) =>
      cart.map((item) =>
        itemId === item.id ? { ...item, packageQty: item.packageQty + 1 } : item
      )
    );
  };

  useEffect(() => {
    let price = 0;
    cart.map((item) => {
      return (price += item?.packageQty * item?.price);
    });
    setPriceEstimation(price);
  }, [cart]);

  useEffect(() => {
    console.log(priceEstimation, "PE");
  }, [priceEstimation]);

  return (
    <>
      <div className={Styles.container}>
        <DeveloperInformation />
        <Text size="h4" width="bold" color="#0a0a0a">
          Atur Keberangkatan
        </Text>
        <div className={Styles.inputDateWrapper}>
          <Text size="l" width="medium" color="#000">
            Tanggal Keberangkatan & Ketersediaan
          </Text>
          <DatePicker value={date} setValue={setDate} disabled />
        </div>
        <div className={Styles.packageContainer}>
          <Text size="l" width="medium" color="#000">
            Paket
          </Text>
          <div className={Styles.packageOptions}>
            {cart.map((el) => (
              <PackageCounter
                name={el?.name}
                price={el?.price}
                amount={el?.packageQty}
                handleDecrement={() => handleDecrement(el?.id)}
                handleIncrement={() => handleIncrement(el?.id)}
                key={el?.id}
              />
            ))}
          </div>
        </div>
        <div className={Styles.additionalContainer}>
          <Text size="l" width="medium" color="#000">
            Fasilitas Tambahan
          </Text>
          <div className={Styles.cards}>
            {additionalList.map((el) => (
              <AdditionalFacilityCounter
                label={el?.name}
                price={el?.price}
                amount={el?.packageQty}
                handleDecrement={() => handleDecrement(el?.id)}
                handleIncrement={() => handleIncrement(el?.id)}
                key={el?.id}
              />
            ))}
          </div>
        </div>
        <div className={Styles.paymentContainer}>
          <Text size="l" width="medium" color="#000">
            Pembayaran
          </Text>
          <div className={Styles.optionWrapper}>
            <Text size="m" width="medium" color="#000">
              Pembayaran Penuh
            </Text>
            <RadioButton
              isActive={paymentScheme === "full"}
              setIsActive={() => setPaymentScheme("full")}
              disabled
            />
          </div>
          <div className={Styles.optionWrapper}>
            <Text size="m" width="medium" color="#000">
              Pembayaran Uang Muka
            </Text>
            <RadioButton
              isActive={paymentScheme === "installment"}
              setIsActive={() => setPaymentScheme("installment")}
              disabled
            />
          </div>
        </div>
      </div>
      <div className={Styles.totalPriceEstimation}>
        <div className={Styles.priceWrapper}>
          <Text size="s" width="regular" color="#9e9e9e">
            Estimasi biaya
          </Text>
          <Text size="h3" width="bold" color="#0a0a0a">
            {`Rp ${priceEstimation === 0 ? "-" : priceEstimation}`}
          </Text>
        </div>
        <Button label="Lanjutkan Pesanan" onClick={() => null} disabled />
        {/* <Button
          label="Lanjutkan Pesanan"
          onClick={() => navigate("/order")}
        /> */}
      </div>
    </>
  );
};

export default PriceSimulator;
