import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import FacilityCard from "@Molecule/FacilityCard";
import useMediaQuery from "@Hooks/useMediaQuery";

const Itenerary = ({ list, ...props }) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  console.log(list, "LIST");

  return (
    <div className={Styles.iteneraryContainer}>
      <Text size={isMobile ? "h4" : "h3"} width="bold" color="#0a0a0a">
        Itenerari
      </Text>
      <div className={Styles.iteneraryContent}>
        {list?.map((data) => (
          <div className={Styles.step}>
            <div className={Styles.stepHeader}>
              <div className={Styles.point}></div>
              <Text size={isMobile ? "l" : "xl"} width="bold" color="#000">
                {`Hari ke-${data?.id}`}
              </Text>
            </div>
            <div className={Styles.stepContent}>
              <Text size="m" width="regular" color="#0a0a0a">
                {data?.desc}
              </Text>
              {/* <div className={Styles.cards}>
                <FacilityCard />
                <FacilityCard />
                <FacilityCard />
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Itenerary;
