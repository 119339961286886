import Styles from "./style.module.scss";
import Text from "@Atom/Text";
import AffiliatorAd from "@Molecule/AffiliatorAd";
import Table from "@Molecule/Table";
import AffiliatorFeeEstimationCard from "@Molecule/AffiliatorFeeEstimationCard";
import DetailsTable from "@Atom/DetailsTable";
import useMediaQuery from "@Hooks/useMediaQuery";
import MECCA from "../../../assets/images/mecca.png";
import Button from "@Atom/Button";
import Information from "./Information";
import Icon from "@Atom/Icon";
import PaymentMethodCard from "@Molecule/PaymentMethodCard";
import BSI_LOGO from "@Assets/images/logo-bsi.png";
import BCA_LOGO from "@Assets/images/logo-bca.png";
import BNI_LOGO from "@Assets/images/logo-bni.png";
import Input from "@Atom/Input";
import { useState } from "react";
import ParticipantDetail from "@Molecule/Modal/ParticipantDetail";
import Modal from "@Atom/Modal";
import AddParticipant from "@Molecule/Modal/AddParticipant";

const OrderDetail = () => {
  const stickyComponentHidden = useMediaQuery("(max-width: 1287px)");
  const [participantDetailModalOpen, setParticipantDetailModalOpen] =
    useState(false);
  const [addParticipantModalOpen, setAddParticipantModalOpen] = useState(false);

  const data = [
    {
      number: 1,
      name: "Alfin Wira Yuda",
      phoneNumber: 81262994188,
      roomType: "Quad",
      additional: "Tambah nama passpor",
      action: "Detail",
    },
    {
      number: 2,
      name: "Alfin Wira Yuda Kai Havertz",
      phoneNumber: 81262994188,
      roomType: "Quad",
      additional: "Tambah nama passpor",
      action: "Detail",
    },
    {
      number: 3,
      name: "Alfin Wira Yuda",
      phoneNumber: 81262994188,
      roomType: "Quad",
      additional: "Tambah nama passpor",
      action: "Detail",
    },
    {
      number: 4,
      name: "Alfin Wira Yuda",
      phoneNumber: 81262994188,
      roomType: "Quad",
      additional: "Tambah nama passpor",
      action: "Detail",
    },
    {
      number: 5,
      name: "Alfin Wira Yuda Kai Havertz",
      phoneNumber: 81262994188,
      roomType: "Quad",
      additional: "Tambah nama passpor",
      action: "Detail",
    },
    {
      number: 6,
      name: "Alfin Wira Yuda",
      phoneNumber: 81262994188,
      roomType: "Quad",
      additional: "Tambah nama passpor",
      action: "Detail",
    },
    {
      number: 7,
      name: "Alfin Wira Yuda",
      phoneNumber: 81262994188,
      roomType: "Quad",
      additional: "Tambah nama passpor",
      action: "Detail",
    },
    {
      number: 8,
      name: "Alfin Wira Yuda Kai Havertz",
      phoneNumber: 81262994188,
      roomType: "Quad",
      additional: "Tambah nama passpor",
      action: "Detail",
    },
    {
      number: 9,
      name: "Alfin Wira Yuda",
      phoneNumber: 81262994188,
      roomType: "Quad",
      additional: "Tambah nama passpor",
      action: "Detail",
    },
    {
      number: 10,
      name: "Alfin Wira Yuda",
      phoneNumber: 81262994188,
      roomType: "Quad",
      additional: "Tambah nama passpor",
      action: "Detail",
    },
  ];

  const typeList = [
    {
      name: "Quad",
      amount: 4,
      totalCost: 140000000,
    },
    {
      name: "Triple",
      amount: 2,
      totalCost: 73000000,
    },
    {
      name: "Double",
      amount: 2,
      totalCost: 76000000,
    },
  ];

  const additionalList = [
    {
      name: "Tambah nama passpor",
      amount: 2,
      totalCost: 2000000,
    },
    {
      name: "Perlengkapan & heandling",
      amount: 1,
      totalCost: 1500000,
    },
  ];

  const columns = [
    {
      name: "number",
      label: "#",
      render: (row) => <span>{row.number}</span>,
    },
    { name: "name", label: "Nama", render: (row) => <span>{row.name}</span> },
    {
      name: "phoneNumber",
      label: "Nomor WhatsApp",
      render: (row) => <span>{row.phoneNumber}</span>,
    },
    {
      name: "roomType",
      label: "Tipe Kamar",
      render: (row) => <span>{row.roomType}</span>,
    },
    {
      name: "additional",
      label: "Fasilitas Tambahan",
      render: (row) => <span>{row.additional}</span>,
    },
    {
      name: "action",
      label: "Action",
      render: (row) => (
        <span onClick={() => setParticipantDetailModalOpen(true)}>
          {row.action}
        </span>
      ),
    },
  ];

  return (
    <div className={Styles.container}>
      {participantDetailModalOpen && (
        <Modal
          handleDone={() => setParticipantDetailModalOpen(false)}
          isOpen={participantDetailModalOpen}
        >
          <ParticipantDetail
            handleDone={() => setParticipantDetailModalOpen(false)}
          />
        </Modal>
      )}
      {addParticipantModalOpen && (
        <Modal
          handleDone={() => setAddParticipantModalOpen(false)}
          isOpen={addParticipantModalOpen}
        >
          <AddParticipant
            handleDone={() => setAddParticipantModalOpen(false)}
          />
        </Modal>
      )}
      <div className={Styles.affiliatorAdWrapper}>
        <AffiliatorAd />
      </div>
      <div className={Styles.content}>
        <Text size="h3" width="bold" color="#000">
          Peserta
        </Text>
        <div className={Styles.contentBody}>
          <div className={Styles.left}>
            <div className={Styles.tableBox}>
              <div className={Styles.table}>
                {/* <div style={{ padding: 16 }}>
                  <Text>Table</Text>
                </div> */}
                <div className={Styles.tableHeader}>
                  <div className={Styles.inputWrapper}>
                    <Input
                      placeholder="Cari nama peserta"
                      icon="magnifying-glass"
                    />
                  </div>
                  <div className={Styles.buttonWrapper}>
                    <Button
                      type="primary"
                      label="Peserta"
                      icon="plus-circle"
                      onClick={() => setAddParticipantModalOpen(true)}
                    />
                  </div>
                </div>
                <Table data={data} columns={columns} itemsPerPage={10} />
                <div className={Styles.tableFooter}>
                  <Text
                    size="m"
                    width="regular"
                    color="#212121"
                  >{`Menampilkan ${data.length} peserta`}</Text>
                </div>
              </div>
            </div>
            {/* <div style={{ backgroundColor: "red", height: 600 }}></div>
             */}
            <div className={Styles.paymentMethodsWrapper}>
              <Text size="h3" width="bold" color="#000">
                Opsi Pembayaran
              </Text>
              <div className={Styles.paymentMethod}>
                <div className={Styles.title}>
                  <Icon
                    icon="building-library"
                    size="24"
                    className={Styles.icon}
                  />
                  <Text size="xl" width="medium" color="#000">
                    Transfer Bank (Manual)
                  </Text>
                </div>
                <PaymentMethodCard
                  type="transfer"
                  bankName="Bank BSI"
                  accountNumber="7177737498"
                  accountOwner="PT Muslimnet Indonesia"
                  bankLogo={BSI_LOGO}
                />
              </div>
              <div className={Styles.paymentMethod}>
                <div className={Styles.title}>
                  <Icon icon="bolt" size="24" className={Styles.icon} />
                  <Text size="xl" width="medium" color="#000">
                    Vitrual Account
                  </Text>
                </div>
                <PaymentMethodCard
                  type="va"
                  bankName="Bank BSI"
                  vaNumber="812777389478824"
                  bankLogo={BSI_LOGO}
                />
                <PaymentMethodCard
                  type="va"
                  bankName="Bank BCA"
                  vaNumber="812777389478824"
                  bankLogo={BCA_LOGO}
                />
                <PaymentMethodCard
                  type="va"
                  bankName="Bank BNI"
                  vaNumber="812777389478824"
                  bankLogo={BNI_LOGO}
                />
              </div>
            </div>
          </div>
          {!stickyComponentHidden && (
            <div className={Styles.stickyComponent}>
              <div className={Styles.orderResume}>
                <div className={Styles.header}>
                  <img src={MECCA} alt="" />
                  <div className={Styles.packageInformation}>
                    <Text size="s" width="regular" color="#9e9e9e">
                      Informasi paket
                    </Text>
                    <Text size="m" width="medium" color="#000">
                      Umrah Reguler 13 Agustus 2023 Paket Sapphire
                    </Text>
                  </div>
                </div>
                <div className={Styles.body}>
                  <Information
                    title="Asal Keberangkatan"
                    value="Jakarta"
                    icon="map-pin"
                  />
                  <Information
                    title="Informasi Perjalanan"
                    value="24 Agustus 2023"
                    icon="calendar-days"
                  />
                  <DetailsTable data={typeList} />
                  <div className={Styles.additionalTableWrapper}>
                    <Text size="l" width="medium" color="#404040">
                      Fasilitas Tambahan
                    </Text>
                    <DetailsTable data={additionalList} />
                  </div>
                  <Information
                    title="Pembayaran"
                    value="Pembayaran Penuh"
                    icon="money"
                  />
                </div>
                <div className={Styles.footer}>
                  <div className={Styles.textWrapper}>
                    <Text size="s" width="regular" color="#9e9e9e">
                      Estimasi total biaya
                    </Text>
                    <Text size="h3" width="bold" color="#0a0a0a">
                      Rp292.500.000
                    </Text>
                  </div>
                  <Button label="Lanjutkan Pembayaran" />
                </div>
              </div>
              <AffiliatorFeeEstimationCard />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
